import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import profile6 from "../../images/profile6.png";
import Modal from "./modals/modal"
import Utility from "../../components/utility";
import axios from '../apiConnection/axios';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Moment from 'react-moment';
import moment from 'moment';
const utilityObj = new Utility;

export default class taskDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editTaskReportShow : false, 
            notes: [],
            note_body:"",
            todayDate:"",
            startDate: new Date(),
            endDate:new Date(),
            excpected_due_date:new Date(),
            defaultStageSelectText: "Prospect",
            showRejectionNote:false,
            TasksListData:[],
            assign_task_data:[],
            stageList: [
              { id: 1, name: "Prospect" },
              { id: 2, name: "Qualify" },
              { id: 3, name: "Develop" },
              { id: 4, name: "Validate" },
            ],
            Message: "",
            MessageType: '',
            HideButtons : false,
            LoadedTask : [{
                
            "task": [
                {
                    "id": 293,
                    "opportunity_id": "6",
                    "task_id": "1",
                    "stage_id": 31,
                    "task_title": "Identify key-decision makers and have developed an initial strategy",
                    "task_description": "null",
                    "task_required": "1",
                    "ordering": 1,
                    "start_date": "Mon Oct 11 2021 05:30:00 GMT+0530 (India Standard Time)",
                    "end_date": "null",
                    "excpected_due_date": null,
                    "status": "1",
                    "task_status": "1",
                    "created_at": "2021-11-16T06:11:40.000Z",
                    "updated_at": "2021-11-16T07:19:27.000Z",
                    "created_by": null,
                    "updated_by": null
                }
            ],
            "sales_rep": {
                "sales_rep_id": "36",
                "first_name": "Rajeev",
                "last_name": "Bansal"
            },
            "sales_manager": {
                "sales_manager_id": 24,
                "first_name": "Mayank",
                "last_name": "Baijal"
            },
            "sales_manager_notes": {
                "normal": [
                    {
                        "id": 7,
                        "opportunity_task_id": 293,
                        "opportunity_id": 6,
                        "note_content_type": "0",
                        "note_type": "1",
                        "note_kind": "0",
                        "user_id": 24,
                        "note_body": "Testing Note",
                        "created_at": "2021-11-16T10:12:30.000Z",
                        "updated_at": "2021-11-16T10:12:30.000Z",
                        "created_by": 1,
                        "updated_by": 1
                    }
                ],
                "rejected": []
            },
            "sales_rep_notes": {
                "normal": [
                    {
                        "id": 10,
                        "opportunity_task_id": 293,
                        "opportunity_id": 6,
                        "note_content_type": "0",
                        "note_type": "0",
                        "note_kind": "0",
                        "user_id": 36,
                        "note_body": "asdads",
                        "created_at": "2021-11-16T10:54:36.000Z",
                        "updated_at": "2021-11-16T10:54:36.000Z",
                        "created_by": 36,
                        "updated_by": null
                    },
                    {
                        "id": 11,
                        "opportunity_task_id": 293,
                        "opportunity_id": 6,
                        "note_content_type": "0",
                        "note_type": "0",
                        "note_kind": "0",
                        "user_id": 36,
                        "note_body": "dffsf",
                        "created_at": "2021-11-16T10:54:36.000Z",
                        "updated_at": "2021-11-16T10:54:36.000Z",
                        "created_by": 36,
                        "updated_by": null
                    }
                ]
            }
            }],
            NotesBySalesRep : [],
            Messageerr : "",
            MessageerrType : null,
            errorArr: [],
            errorshow:0,
        };
       
        
        this.handleChange = this.handleChange.bind(this);
        this.assignTask = this.assignTask.bind(this);
        this.changeRejectionNote = this.changeRejectionNote.bind(this);
    }
    
    componentDidMount() {

        if ((this.props.match.params.opp_id!="") && (this.props.match.params.opp_id)){
            if (this.props.match.params.opp_id=="opportunities"){
                window.location = '../'+this.props.match.params.opp_id;
            }
            const user_id = localStorage.getItem('userid');
            const user_role = localStorage.getItem('adminsrToken');
            
            this.setState({ user_role, user_id, opp_id: this.props.match.params.opp_id, }, () => {
                this.loadTasks();

            });
        
        }
    }
    componentDidUpdate() {
        if ((this.props.match.params.opp_id!="") && (this.props.match.params.opp_id)){
            if ((this.props.match.params.opp_id=="opportunities") || (this.props.match.params.opp_id=="dashboard") || (this.props.match.params.opp_id=="reports") || (this.props.match.params.opp_id=="coaching") || (this.props.match.params.opp_id=="calendar") || (this.props.match.params.opp_id=="myprofile")){
                window.location = '../'+this.props.match.params.opp_id;
            }
        }
    }

    loadTasks = async () => {
        utilityObj.loaderView("show");

        const { opp_id, user_id, user_role} = this.state;

        const payload = {
            opportunity_id: opp_id,
            user_id : user_id,
            role_id : user_role,            
        }
        console.log('About to call: '+axios.defaults.baseURL + '/opportunity/get-task');
        console.log('with payload: ', payload)
        await axios.post(`opportunity/get-task`, payload, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
          }
        })
          .then(res => {
            console.log(res.data.data[0].opportunity[0].opp_name)
            if (res.data.code === 200) {
              this.setState({ TasksListData: res.data.data[1].task_details, OppoDetails:res.data.data[0].opportunity[0] });
              
            }
            utilityObj.loaderView("hide");
          })
          .catch((error) => {
            console.log(error)
          })
    }

    setTaskReportShow = (newValue) => {
        this.setState({editTaskReportShow:newValue});
    }


    editTaskReport = () => {
        return(<>
                    <form>
                        <div className="form-group">
                            <label className="mb-0">Task Name</label>
                            <div className="feild-text py-3 f-600 f2015">
                                Developed business risk impact statement.
                              </div>
                        </div>
                        <div className="form-group">
                            <label className="mb-0">Task Status</label>
                            <div className="feild-text py-3 f-600 f2015 color_green">
                                Completed
                            </div>
                            <div className="feild-text py-3 f-600 f2015 color_red">
                                Completed Rejected
                            </div>
                            <div className="feild-text py-3 f-600 f2015 color_yellow">
                                Awaiting Review
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="mb-0">Assigned Date</label>
                                    <div className="feild-text py-3 f-600 f2015">
                                        06-09-2021
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="mb-0">Completed Date</label>
                                    <div className="feild-text py-3 f-600 f2015">
                                        06-15-2021
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="mb-0">Notes by Vania Rice</label>
                            <div className="feild-text py-1 f-500 f2015">
                                Lorem ipsum dolor sit amet, consectetur ading elit, sed do eiusmod tempor incididunt ut lorem ipsum dolor sit amet, consectetur
                              </div>
                        </div>
                        <div className="form-group">
                            <label className="mb-0">Notes by George Swanson</label>
                            <div className="feild-text py-1 f-500 f2015">
                                Lorem ipsum dolor sit amet, consectetur ading elit, sed do eiusmod tempor incididunt ut lorem ipsum dolor sit amet, consectetur
                              </div>
                        </div>
                        <div className="form-group">
                            <label className="mb-0 color_red">Rejection Notes</label>
                            <textarea className="form-control rejection-note f-600 f2015" placeholder="Notes"></textarea>
                        </div>
                        <div className="modal-footer justify-content-center">
                            <button type="button" className="btn dark-btn mx-2" onClick={()=>this.setTaskReportShow(false)}>Save</button>
                            <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={()=>this.setTaskReportShow(false)}>Cancel</button>
                        </div>
                    </form>        
        </>);
    }


    setTaskReportShow2 = (newValue) => {
        this.setState({editTaskReportShow2:newValue});
    }


    editTaskReport2 = () => {
        return(<>
                    <form>
                        <div className="form-group">
                            <label className="mb-0">Task Name</label>
                            <div className="feild-text py-3 f-600 f2015">
                                Developed business risk impact statement.
                              </div>
                        </div>
                        <div className="form-group">
                            <label className="mb-0">Task Status</label>
                            <div className="feild-text py-3 f-600 f2015 color_green">
                                Completed
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="mb-0">Assigned Date</label>
                                    <div className="feild-text py-3 f-600 f2015">
                                        06-09-2021
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="mb-0">Completed Date</label>
                                    <div className="feild-text py-3 f-600 f2015">
                                        06-15-2021
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="mb-0">Notes by Vania Rice</label>
                            <div className="feild-text py-1 f-500 f2015">
                                Lorem ipsum dolor sit amet, consectetur ading elit, sed do eiusmod tempor incididunt ut lorem ipsum dolor sit amet, consectetur
                              </div>
                        </div>
                        <div className="form-group">
                            <label className="mb-0">Notes by George Swanson</label>
                            <div className="feild-text py-1 f-500 f2015">
                                Lorem ipsum dolor sit amet, consectetur ading elit, sed do eiusmod tempor incididunt ut lorem ipsum dolor sit amet, consectetur
                              </div>
                        </div>
                    </form>        
        </>);
    }    


    setTaskReportShow3 = (newValue, task_id) => {
        this.setState({editTaskReportShow3:newValue, LoadTaskID : task_id}, ()=>{
            if (newValue=== true){
                this.setState({notes:[], errorArr:[], errorshow:0, NotesBySalesRep:[]});
            this.LoadTaskByID();
            } else {
                this.setState({
                    Message : '',
                    MessageType : null,
                    Messageerr : '',
                    MessageerrType : null
                })
            }
        });
    }
    LoadTaskByID = async () => {
        utilityObj.loaderView("show");

        const { LoadTaskID } = this.state;

        const payload = {
            opportunity_task_id: LoadTaskID,        
        }
        await axios.post(`opportunity/get-single-task`, payload, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
          }
        })
          .then(res => {
            if (res.data.code === 200) {
              this.setState({ LoadedTask: res.data.data});
              console.log(res.data.data[0]);
            }
            utilityObj.loaderView("hide");
          })
          .catch((error) => {
            console.log(error)
          })
    }
    updatingNotes = (e, index) => {
        var NotesBySalesRep = this.state.NotesBySalesRep;
        var errorArr = this.state.errorArr;
        let errorshow = 0;


        if (e.target.value.length>=501){
            e.preventDefault();
            errorArr[index] = 1; 
            this.setState({
                errorArr,
            }, ()=> {
                errorArr.forEach((item)=>{
                    errorshow = (item===1) ? 1 : errorshow;
                });
        
                if (errorshow===1) {
                    this.setState({
                        errorshow,
                        Messageerr : 'Note cannot be more than 500 characters.',
                        MessageerrType : "danger"
                    })
                } else {
                    this.setState({
                        errorshow,
                        Messageerr : '',
                        MessageerrType : null
                    })
                }                
            });
        } else {

            errorArr[index] = 0; 
            this.setState({
                errorArr,
            }, ()=>{
                NotesBySalesRep[index] = e.target.value; 
                this.setState({
                    NotesBySalesRep
                }, ()=>{
                    console.log( NotesBySalesRep);
                    errorArr.forEach((item)=>{
                        errorshow = (item===1) ? 1 : errorshow;
                    });
            
                    if (errorshow===1) {
                        this.setState({
                            errorshow,
                            Messageerr : 'Note cannot be more than 500 characters.',
                            MessageerrType : "danger"
                        })
                    } else {
                        this.setState({
                            errorshow,
                            Messageerr : '',
                            MessageerrType : null
                        })
                    }
                })
            })
        }
        
    }

    submitNotes = async () => {
        utilityObj.loaderView("show");

        const {NotesBySalesRep, user_id, LoadTaskID} = this.state;

        const payload = {
            task_id: LoadTaskID,
            sales_rep_id: user_id,
            notes: NotesBySalesRep      
        }
        await axios.post(`opportunity/add-notes`, payload, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
          }
        })
          .then(res => {
            if (res.data.code === 200) {
                 this.setState({notes:[], NotesBySalesRep:[]}, ()=> {
                    this.setState({
                        Message : 'Notes updated successfully.',
                        MessageType : "success"
                    }, ()=>{
                        this.LoadTaskByID();
                    })
                    setTimeout(() => { this.setTaskReportShow3(false); this.componentDidMount();}, 3000);
                 })
              console.log(res);
            } else {
                this.setState({
                    Message : res.data.message,
                    MessageType : "danger"
                })
                setTimeout(() => { 
                    this.setState({
                        Message : '',
                        MessageType : null
                    })                        
                }, 3000);
            }
            utilityObj.loaderView("hide");
          })
          .catch((error) => {
            console.log(error)
          })
    }

    editTaskReport3 = () => {
        const {LoadedTask, NotesBySalesRep, user_role, notes, Message, MessageType, Messageerr, MessageerrType, errorArr, errorshow } = this.state;
        var taskStatus, taskColor = "";
        if (user_role==4){
            if (LoadedTask[0].task[0].task_status=="0"){
                taskStatus = "Not Assigned";
                taskColor = "color_gray";
            } else if (LoadedTask[0].task[0].task_status=="1"){
                taskStatus = "Assigned";
                taskColor = "color_blue";
            } else if (LoadedTask[0].task[0].task_status=="2"){
                var taskStatus = "Scheduled";
                var taskColor = "color_blue";
            } else if (LoadedTask[0].task[0].task_status=="3"){
                var taskStatus = "Rejected";
                var taskColor = "color_red";
            } else if (LoadedTask[0].task[0].task_status=="5"){
                var taskStatus = "Completed With Sessions";
                var taskColor = "color_green";
            } else if (LoadedTask[0].task[0].task_status=="6"){
                var taskStatus = "Completed";
                var taskColor = "color_green";
            } else if (LoadedTask[0].task[0].task_status=="7"){
                var taskStatus = "Approved";
                var taskColor = "color_green";
            }
        }    

        var start_date = (LoadedTask[0].task[0].start_date!=null && LoadedTask[0].task[0].start_date!="") ? new Date(LoadedTask[0].task[0].start_date) : '-';
        var end_date = (LoadedTask[0].task[0].updated_at!=null && LoadedTask[0].task[0].updated_at!='null' && LoadedTask[0].task[0].updated_at!="") ? new Date(LoadedTask[0].task[0].updated_at) : '-';
        var excpected_due_date = (LoadedTask[0].task[0].excpected_due_date!=null && LoadedTask[0].task[0].excpected_due_date!="") ? new Date(LoadedTask[0].task[0].excpected_due_date) : '-';
                    

        console.log(LoadedTask[0].task[0].task_title);
        if (LoadedTask[0].task[0].task_title && LoadedTask[0].task[0].task_title!="" && LoadedTask[0].task[0].task_title!=null && LoadedTask[0].task[0].task_title!='null') {
        return(<>
                    <form>
                    {((Message) && (Message !== null)) ?
                        (
                            <div className={`alert alert-${MessageType}`}>
                            {Message}
                            </div>
                        ) : (``)
                        }                        
                        <div className="form-group">
                            <label className="mb-0">Task Name</label>
                            <div className="feild-text py-3 f-600 f2015">
                                {LoadedTask[0].task[0].task_title}
                              </div>
                        </div>
                        <div className="form-group">
                            <label className="mb-0">Task Status</label>
                            <div className={`feild-text py-3 f-600 f2015 ${taskColor}`}>
                                {taskStatus}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="mb-0">Assigned Date</label>
                                    <div className="feild-text py-3 f-600 f2015">
                                        {(start_date!='-') ? (
                                            <Moment format="MM-DD-YYYY">
                                                {start_date}
                                            </Moment>
                                        ) : (`-`)}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="mb-0">Completed Date</label>
                                    <div className="feild-text py-3 f-600 f2015">
                                    {((end_date!='-') && (taskStatus=="Approved")) ? (
                                            <Moment format="MM-DD-YYYY">
                                                {end_date}
                                            </Moment>
                                        ) : (`-`)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="mb-0">Expected Completion Date</label>
                                    <div className="feild-text py-3 f-600 f2015">
                                    {(excpected_due_date!='-') ? (
                                            <Moment format="MM-DD-YYYY">
                                                {excpected_due_date}
                                            </Moment>
                                        ) : (`-`)}
                                    </div>
                                </div>
                            </div>
                        </div>                     
                        {(LoadedTask[0].sales_manager_notes.normal && LoadedTask[0].sales_manager_notes.normal.length>0) ? (
                           LoadedTask[0].sales_manager_notes.normal.map(Notes => 
                                    <div className="form-group">
                                        <label className="mb-0 w-100">Notes by {LoadedTask[0].sales_manager.first_name +' '+LoadedTask[0].sales_manager.last_name} </label>
                                        <div className="feild-text py-1 word-break-all f-500 f2015">
                                        {`${Notes.note_body}`}
                                        </div>                                        
                                    </div>
                            )
                        ) : null}                              
                        {(LoadedTask[0].sales_manager_notes.rejected && LoadedTask[0].sales_manager_notes.rejected.length>0) ? (
                           LoadedTask[0].sales_manager_notes.rejected.map(Notes => 
                                    <div className="form-group">
                                        <label className="mb-0 color_red">Rejection Notes</label>
                                        <div className="feild-text py-1 f-500 f2015">
                                        {`${Notes.note_body}`}
                                        </div>                                        
                                    </div>
                            )
                        ) : null}  

                        <div className="form-head my-3 f2015  clearfix"><span className="bg-white d-inline-block f-600 pr-3">Notes</span> 
                            {(LoadedTask[0].task[0].task_status=="1" || LoadedTask[0].task[0].task_status=="3") ? (
                                <small className="float-right bg-white d-inline-block pl-3 link" onClick={ () => this.appendNotes() }> + Add</small>
                            ) :null}
                        </div>
                        <div className="notes-wrapper pr-2">
                        <div id="error_msg">
                        {((Messageerr) && (Messageerr !== null) && (errorshow !==0)) ?
                        (
                            <div className={`alert alert-${MessageerrType}`}>
                            {Messageerr}
                            </div>
                        ) : (``)
                        }                               
                        </div>                    
                            {notes.map((currentelement, index, arrayobj) => 
                            (<> 

                        <div className="form-group" key={currentelement}>
                            <label className={`mb-0 w-100 ${(errorArr[index]===1) ? "color_red" :null}`}>Notes by {LoadedTask[0].sales_rep.first_name +' '+LoadedTask[0].sales_rep.last_name} <span className="float-right bg-white d-inline-block pl-3 link color_red" onClick={ () => this.removeNotes(index) }> - Clear</span></label>
                            <textarea className={`form-control f-500 f2015 ${(errorArr[index]===1) ? "color_red" :null}`} placeholder="Type a Note" onChange={(e) => {this.updatingNotes(e, index)}} defaultValue={NotesBySalesRep[index]}></textarea>
                        </div>
                            </>)
                            )}   
                        {(LoadedTask[0].sales_rep_notes.normal && LoadedTask[0].sales_rep_notes.normal.length>0) ? (
                           LoadedTask[0].sales_rep_notes.normal.map(Notes => 
                                    <div className="form-group">
                                        <label className="mb-0 w-100">Notes by {LoadedTask[0].sales_rep.first_name +' '+LoadedTask[0].sales_rep.last_name} </label>
                                        <textarea className="form-control f-500 f2015" disabled placeholder="Type a Note" value={`${Notes.note_body}`}></textarea>
                                    </div>
                            )
                        ) : null}    

                        </div>
                        

                        {(LoadedTask[0].task[0].task_status=="1" || LoadedTask[0].task[0].task_status=="3") ? (

                            <div className="modal-footer justify-content-center">
                                {(errorshow!==1) ? (
                                    <button type="button" className="btn dark-btn mx-2" onClick={()=>this.submitNotes()}>Save</button>
                                ) : (
                                    <button type="button" className="btn light-btn mx-2 disabled" disabled title={`Notes should be max 500 chars.`}>Save</button>
                                )}
                                <button type="button" className="btn light-btn cancel-btn mx-2" onClick={()=>this.setTaskReportShow3(false)}>Cancel</button>
                            </div>                    
                        ) : null }

                    </form>        
        </>);
        }
    }    


    setTaskReportShow4 = (newValue) => {
        this.setState({editTaskReportShow4:newValue});
    }


    editTaskReport4 = () => {
        return(<>
                    <form>
                        <div className="form-group">
                            <label className="mb-0">Task Name</label>
                            <div className="feild-text py-3 f-600 f2015">
                                Developed business risk impact statement.
                              </div>
                        </div>
                        <div className="form-group">
                            <label className="mb-0">Task Status</label>
                            <div className="feild-text py-3 f-600 f2015 color_yellow">
                                Awaiting Review
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="mb-0">Assigned Date</label>
                                    <div className="feild-text py-3 f-600 f2015">
                                        06-09-2021
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="mb-0">Completed Date</label>
                                    <div className="feild-text py-3 f-600 f2015">
                                        06-15-2021
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="mb-0">Notes by Vania Rice</label>
                            <div className="feild-text py-1 f-500 f2015">
                                Lorem ipsum dolor sit amet, consectetur ading elit, sed do eiusmod tempor incididunt ut lorem ipsum dolor sit amet, consectetur
                              </div>
                        </div>
                        <div className="form-group">
                            <label className="mb-0">Notes by George Swanson</label>
                            <div className="feild-text py-1 f-500 f2015">
                                Lorem ipsum dolor sit amet, consectetur ading elit, sed do eiusmod tempor incididunt ut lorem ipsum dolor sit amet, consectetur
                              </div>
                        </div>
                    {(this.state.showRejectionNote===true)?
                    (<>
                            <div className="form-group">
                                <label className="mb-0 color_red">Rejection Notes</label>
                                <textarea className="form-control rejection-note f-600 f2015" placeholder="Notes"></textarea>
                            </div>
                        <div className="modal-footer justify-content-center">
                            <button type="button" className="btn dark-btn mx-2" onClick={()=>this.setTaskReportShow4(false)}>Save</button>
                            <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={()=>this.setshowRejectionNote(false)}>Cancel</button>
                        </div>                    
                    </>):
                    (<>
                        <div className="modal-footer justify-content-center">
                            <button type="button" className="btn dark-btn mx-2" onClick={()=>this.setTaskReportShow4(false)}>Approve</button>
                            <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={()=>this.setshowRejectionNote(true)}>Reject</button>
                        </div>                    
                    </>)}                        
                    </form>        
        </>);
    }

    setshowRejectionNote = (newValue) => {
        this.setState({showRejectionNote:newValue});
    }

    setTaskReportShow5 = (newValue, task_id, sales_rep_note_exists) => {
        if (newValue==true){
            this.setState({editTaskReportShow5:newValue,  LoadTaskID : task_id, sales_rep_note_exists});
            this.setState({
                Message: null,
                MessageType: null,
                HideButtons: false,
              });
        } else {
            this.setState({editTaskReportShow5:newValue});
        }
    }

    markTaskComplete = async () => {
        const {LoadTaskID, user_id} = this.state;
        const completeTaskinfo = {
            "task_id": LoadTaskID,
            "user_id" : user_id
          }
          await axios.post(`opportunity/task-mark-complete`, completeTaskinfo, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8',
            }
          }).then(res => {
            console.log(res);
            if (res.data.code === 200) {
              this.setState({
                Message: "Task has been marked completed.",
                MessageType: 'success',
                HideButtons: true,
              });
      
              setTimeout(
                () => {
                    this.setState({
                        Message: null,
                        MessageType: null,
                        HideButtons: false,
                      });
        
                this.setTaskReportShow5(false);
                this.componentDidMount();
                },
                3000);
            }
            else {
              this.setState({
                Message: res.message,
                MessageType: 'danger',
                fileextractDone: 0,
              });
            }
          })
            .catch((error) => {
              console.log(error)
            })
    }

    editTaskReport5 = () => {
        const {HideButtons, Message, MessageType, sales_rep_note_exists} = this.state;
        return(<>
    <div className="my-3">
    {((Message) && (Message !== null)) ?
            (
              <div className={`alert alert-${MessageType}`}>
                {Message}
              </div>
            ) : (``)
          }
    {(sales_rep_note_exists=="yes") ? (
    <>
    <p className="m-0 text-center"> Completing the task will submit the task for review to the Sales Manager. </p>
    <p className="m-0 mt-3 text-center"> Are you sure you want to complete this task? </p>
      <div className="modal-footer border-0 justify-content-center">
          {(HideButtons===false) ? (
              <>
                <button type="button" className="btn dark-btn mx-2" onClick={() => this.markTaskComplete()}>Yes</button>
                <button type="button" className="btn light-btn mx-2" onClick={() => this.setTaskReportShow5(false)} >No</button>              
              </>
          ) : null}
      </div>
    </>
    ) : (
        <>
    <p className="m-0 text-center"> Task cannot be completed. Please add notes to the task for the Sales Manager to review. </p>
      <div className="modal-footer border-0 justify-content-center">
                <button type="button" className="btn light-btn mx-2" onClick={() => this.setTaskReportShow5(false)} >Ok</button>              
      </div>
        
        </>
    )}

    </div>
        </>);
    }    


    handleChange(event) {
        console.log(event.target);
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if(target.name=="note_body"){
            if(value.length>500){
                this.setState({Message:"Note length should be less than 500 characters.",MessageType:"danger"});
            }else{
                this.setState({Message:"",MessageType:""});
                this.setState({ note_body: value });
            }
        }
       
        console.log("FORM DATA", this.state);
      }

    assignTask = async (task_id,sales_manager_id) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = mm + '-' + dd + '-' + yyyy;


        var excpected_due_date = this.state.excpected_due_date;
        var edd = String(excpected_due_date.getDate()).padStart(2, '0');
        var emm = String(excpected_due_date.getMonth() + 1).padStart(2, '0'); //January is 0!
        var eyyyy = excpected_due_date.getFullYear();

        excpected_due_date = emm + '-' + edd + '-' + eyyyy;

        const payload = {
            task_id:task_id,
            sales_manager_id:sales_manager_id,
            assigned_date:today,
            excpected_due_date:excpected_due_date,
            note:this.state.note_body
        }
        console.log("ASSIGN TASK PAYLOAD:",payload);
        var message="";
        var message_type="";
        if(payload.excpected_due_date==""){
            message = "Expected due date is required.";
            message_type = "danger";
            this.setState({
                Message: message,
                MessageType: message_type
              });
              return false;
        } 
        if(payload.note==""){
            message = "Note is required.";
            message_type = "danger";
            this.setState({
                Message: message,
                MessageType: message_type
              });
              return false;
        }else if(payload.note.length>500){
            message = "Note length should be less than 500 characters.";
                message_type = "danger";
                this.setState({
                    Message: message,
                    MessageType: message_type
                  });
                  return false;
        }
        // else if(payload.note){
        //     message = "Note length should be less than 500 characters.";
        //     message_type = "danger";
        //     this.setState({
        //         Message: message,
        //         MessageType: message_type
        //       });
        //       return false;
        // }
        
       

        console.log("ASSIGN TASK DATA:",payload);
        await axios.post(`opportunity/assign-task`, payload, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
          }
        })
          .then(res => {

            utilityObj.loaderView("hide");
            if (res.data.code === 200) {
                // this.setState({
                //     Message: "",
                //     MessageType: ""
                //   });
                  this.setState({
                    Message: "Task assigned successfully",
                    MessageType: 'success'
                });

                  setTimeout(() => {this.setState({editTaskReportShow6:false});}, 1500);

                // this.setState({editTaskReportShow6:false});
              this.loadTasks();
            }
            
          })
          .catch((error) => {
            console.log(error)
          })
        
    }
    setTaskReportShow6 = (newValue, taskId) => {
        if (newValue===true){
            this.setState({excpected_due_date:"",note_body:"",Message:"",MessageType:"", taskId, rejection:false, rejectionNote:''}, ()=> {
                this.getTaskDetailForModal(taskId);
            });
        } 
        this.setState({editTaskReportShow6:newValue});
        this.getTaskDetailForModal(newValue);            
        console.log("TAsk ID:",newValue);
        if (newValue === false) {
            this.setState({excpected_due_date:new Date(),note_body:"",Message:"",MessageType:""});
        }
    }



    getTaskDetailForModal = async (edit_assign_task_id) => {
        utilityObj.loaderView("show");


        const payload = {
            opportunity_task_id: edit_assign_task_id
        }
        await axios.post(`opportunity/get-single-task`, payload, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
          }
        })
          .then(res => {
            utilityObj.loaderView("hide");
            if (res.data.code === 200) {
                console.log("GET SINGLE TASK:",res.data.data[0].task[0].task_title);
                this.setState({assign_task_data:res.data.data});
            }
            
          })
          .catch((error) => {
            console.log(error)
          })
    }
    
    editTaskReport6 = () => {
        const{assign_task_data,Message,MessageType, rejection, rejectionNote}=this.state
        console.log("assign_task_data",assign_task_data);
                    
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = mm + '-' + dd + '-' + yyyy;
var taskStatus=""
var taskColor="";
    if(assign_task_data.length>0){
        var start_date = (assign_task_data[0].task[0].start_date!=null && assign_task_data[0].task[0].start_date!="") ? new Date(assign_task_data[0].task[0].start_date) : '-';
        var end_date = (assign_task_data[0].task[0].updated_at!=null && assign_task_data[0].task[0].updated_at!='null' && assign_task_data[0].task[0].updated_at!="") ? new Date(assign_task_data[0].task[0].updated_at) : '-';
        var excpected_due_date = (assign_task_data[0].task[0].excpected_due_date!=null && assign_task_data[0].task[0].excpected_due_date!="") ? new Date(assign_task_data[0].task[0].excpected_due_date) : '-';

          if (assign_task_data[0].task[0].task_status=="0"){
                taskStatus = "Not Assigned";
                taskColor = "color_gray";
            } else if (assign_task_data[0].task[0].task_status=="1"){
                taskStatus = "Assigned";
                taskColor = "color_blue";
            } else if (assign_task_data[0].task[0].task_status=="2"){
                var taskStatus = "Scheduled";
                var taskColor = "color_blue";
            } else if (assign_task_data[0].task[0].task_status=="3"){
                var taskStatus = "Rejected";
                var taskColor = "color_red";
            } else if (assign_task_data[0].task[0].task_status=="5"){
                var taskStatus = "Completed With Sessions";
                var taskColor = "color_green";
            } else if (assign_task_data[0].task[0].task_status=="6"){
                var taskStatus = "Awaiting Review";
                var taskColor = "color_light_gray";
            } else if (assign_task_data[0].task[0].task_status=="7"){
                var taskStatus = "Approved";
                var taskColor = "color_green";
            }
    }
        return(<>
         {((Message) && (Message !== null)) ?
            (
              <div className={`alert alert-${MessageType}`}>
                {Message}
              </div>
            ) : (``)
          }
        {assign_task_data.length>0?
                    <form>
                        <div className="form-group">
                            <label className="mb-0">Task Name</label>
                            <div className="feild-text py-3 f-600 f2015">
                              {assign_task_data[0].task[0].task_title}
                              </div>
                        </div>
                        <div className="row">
                        <div className="col-md-6">
                                <div className="form-group">
                                    <label className="mb-0">Task Status</label>
                                    <div className={`feild-text py-3 f-600 f2015 ${taskColor}`}>
                                    {taskStatus}
                                    </div>
                                </div>
                            </div> 
                            <div className="col-md-6">
                            <div className="form-group">
                                    <label className="mb-0">Assigned Date</label>
                                    <div className="feild-text py-3 f-600 f2015">
                                    {(taskStatus == "Not Assigned")? `-`:
                                    (start_date!='-') ? (
                                            <Moment format="MM-DD-YYYY">
                                                {start_date}
                                            </Moment>
                                        ) : (`-`)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                            <div className="form-group">
                                <label className="mb-0">Expected Due Date <span className="mandatory color_red">*</span></label>
                                <div className="feild-text py-3 f-600 f2015">
                                {assign_task_data[0].task[0].excpected_due_date ?  moment(assign_task_data[0].task[0].excpected_due_date).format('MM-DD-YYYY')  :<>
                                <span className="date-icon"><i className="ri-calendar-line"></i></span>
                                <DatePicker
                                    dateFormat="MM-dd-yyyy"
                                    className="form-control f-600 f2015 with-icon"
                                    selected={this.state.excpected_due_date}
                                    onChange={(date) => this.setState({ excpected_due_date: date })}
                                    minDate={moment().toDate()}
                                    maxDate={moment().add(365, 'days').toDate()}
                                />
                                </>}
                                </div>
                            </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="mb-0">Completed Date</label>
                                    <div className="feild-text py-3 f-600 f2015">
                                    {((end_date!='-') && (taskStatus=="Approved")) ? (
                                            <Moment format="MM-DD-YYYY">
                                                {end_date}
                                            </Moment>
                                        ) : (`-`)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="form-group">
                            <label className="mb-0">Notes by {assign_task_data[0].sales_rep.first_name} {assign_task_data[0].sales_rep.last_name}</label>
                            <div className="feild-text py-1 f-500 f2015">
                                {assign_task_data[0].sales_rep_notes.normal.length>0?
                                "Lorem ipsum dolor sit amet, consectetur ading elit, sed do eiusmod tempor incididunt ut lorem ipsum dolor sit amet, consectetur"
                                :"-"}
                              </div>
                        </div> */}
                           
                        {/* <div className="form-head my-3 f2015  clearfix"><span className="bg-white d-inline-block f-600 pr-3">Notes</span> <small className="float-right bg-white d-inline-block pl-3 link" onClick={ () => this.appendNotes() }> + Add</small></div> */}
                        


                        <div className="form-group">
                            <label className="mb-0  w-100">Notes by {assign_task_data[0].sales_manager.first_name} {assign_task_data[0].sales_manager.last_name} <span className="mandatory color_red">*</span> </label>
                            {(assign_task_data[0].sales_manager_notes.normal.length > 0 ) ?
                            assign_task_data[0].sales_manager_notes.normal.map(Notes => 
                            <textarea className="form-control f-500 f2015" disabled placeholder="Type a Note" value={`${Notes.note_body}`}></textarea>
                             )
                            :<textarea className="form-control f-500 f2015"  name="note_body" placeholder="Type a Note" value={this.state.note_body} onChange={this.handleChange}></textarea>                                
                            }
                        </div>
                        <div className="form-group">
                            {(assign_task_data[0].sales_manager_notes.rejected.length>0 ) ?           
                              assign_task_data[0].sales_manager_notes.rejected.map(Notes => 
                                        <>
                                        <label className="mb-0  w-100 color_red">Rejection Note</label>
                                        <textarea className="form-control f-500 f2015" disabled placeholder="Type a Note" value={`${Notes.note_body}`}></textarea>                            
                                        </>
                             )
                            :null                                
                            }
                        </div>
                            {this.state.notes.map(input => 
                            (<> 

                        {/* <div className="form-group">
                            <label className="mb-0  w-100">Notes by  {assign_task_data[0].sales_manager.first_name} {assign_task_data[0].sales_manager.last_name}  <span className="float-right bg-white d-inline-block pl-3 link color_red" onClick={ () => this.removeNotes(input) }> - Clear</span></label>
                            <textarea className="form-control f-500 f2015" name="note_body" placeholder="Type a Note" onChange={this.handleChange}></textarea>
                        </div> */}
                            </>)
                            )}

                            <div className="notes-wrapper">
                                {(assign_task_data[0].sales_rep_notes.normal && assign_task_data[0].sales_rep_notes.normal.length>0) ? (
                                    assign_task_data[0].sales_rep_notes.normal.map(Notes => 
                                        <div className="form-group">
                                            <label className="mb-0 w-100">Notes by {assign_task_data[0].sales_rep.first_name +' '+assign_task_data[0].sales_rep.last_name} </label>
                                            <textarea className="form-control f-500 f2015" disabled placeholder="Type a Note" value={`${Notes.note_body}`}></textarea>
                                        </div>
                                    )
                                ) :  <div className="form-group">
                                            <label className="mb-0 w-100">Notes by {assign_task_data[0].sales_rep.first_name +' '+assign_task_data[0].sales_rep.last_name} </label>
                                            -</div>
                                } 
                            </div>

                            {(rejection==true) ? (
                                <div className="form-group">
                                            <label className="mb-0 color_red">Rejection Notes</label>
                                            <div className="feild-text py-1 f-500 f2015">
                                            <textarea className="form-control f-500 f2015" placeholder="Type a Note" name="rejectionNote" onChange={this.changeRejectionNote} value={this.state.rejectionNote}></textarea>
                                            </div>                                        
                                </div>
                            ) : null}



                        <div className="modal-footer justify-content-center">

                            {((rejection!==true) && (taskStatus == "Not Assigned")) ?<>
                            <button type="button" className="btn dark-btn mx-2" onClick={()=>this.assignTask(assign_task_data[0].task[0].id,assign_task_data[0].sales_manager.sales_manager_id)}>Save</button>
                            <button type="button" className="btn light-btn cancel-btn mx-2" onClick={()=>this.setTaskReportShow6(false)}>Cancel</button>
                            </>:(null)}
                            {((rejection!==true) && (taskStatus == "Awaiting Review" )) ?<>
                            <button type="button" className="btn dark-btn mx-2" onClick={()=>this.approveTask()}>Approve</button>
                            <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={()=>this.setRejection(true)}>Reject</button>
                            </>:(null)}

                            {(rejection==true) ? (
                                <>
                                <button type="button" className="btn dark-btn mx-2" onClick={()=>this.rejectTask()}>Save</button>
                                <button type="button" className="btn light-btn  cancel-btn mx-2" onClick={()=>this.setRejection(false)}>Cancel</button>                            
                                </>
                            ) : null}    



                        </div>                    

                    </form>    
                    :null}    
        </>);
        
    } 
    changeRejectionNote(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if(target.name=="rejectionNote"){
            if(value.length>500){
                this.setState({Message:"Note length should be less than 500 characters.", MessageType:"danger"});
            }else{
                this.setState({Message:"", MessageType:""});
                this.setState({ rejectionNote : value });
            }
        }
       }

    setRejection = (newValue) => {
        this.setState({rejection: newValue, rejectionNote : ''});
    }

    rejectTask = async () => {
        const {taskId, user_id, rejectionNote} = this.state;
        utilityObj.loaderView("show");
        const payload = {
            "task_id" : taskId,
            "status" : "reject",
            "user_id" : user_id,
            "note" : rejectionNote,
        }
        await axios.post(`opportunity/task-approve-reject`, payload, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
          }
        })
          .then(res => {
            utilityObj.loaderView("hide");
            if (res.data.code === 200) {
                this.setState({
                    Message : "Task rejected successfully.",
                    MessageType : 'success'
                }, ()=>{
                    setTimeout(() => { this.setState({
                        Message : null,
                        MessageType : null
                    });
                    this.setTaskReportShow6(false);
                    this.componentDidMount();
                }, 3000);
                })


            } else {
                this.setState({
                    Message : res.data.message,
                    MessageType : 'danger'
                }, ()=>{
                    setTimeout(() => { this.setState({
                        Message : null,
                        MessageType : null
                    }); }, 3000);
                })
            }
            
          })
          .catch((error) => {
            console.log(error)
          })
    }

    approveTask = async () => {
        const {taskId, user_id} = this.state;
        utilityObj.loaderView("show");


        const payload = {
            "task_id" : taskId,
            "status" : "approve",
            "user_id" : user_id            
        }
        await axios.post(`opportunity/task-approve-reject`, payload, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
          }
        })
          .then(res => {
            utilityObj.loaderView("hide");
            if (res.data.code === 200) {
                this.setState({
                    Message : "Task approved successfully.",
                    MessageType : 'success'
                }, ()=>{
                    setTimeout(() => { this.setState({
                        Message : null,
                        MessageType : null
                    });
                    this.setTaskReportShow6(false);
                    this.componentDidMount();
                }, 3000);
                })


            } else {
                this.setState({
                    Message : res.data.message,
                    MessageType : 'danger'
                }, ()=>{
                    setTimeout(() => { this.setState({
                        Message : null,
                        MessageType : null
                    }); }, 3000);
                })
            }
            
          })
          .catch((error) => {
            console.log(error)
          })
    }
    

    setTaskReportShow7 = (newValue) => {
        this.setState({editTaskReportShow7:newValue});
    }


    editTaskReport7 = () => {
        return(<>
    <div className="my-3">
    <p className="m-0 text-center">
      Task cannot be completed. Please add notes to the task for the Sales Manager to review.</p>
      <p>          

      </p>
      <div className="modal-footer border-0 justify-content-center">
            <button type="button" className="btn light-btn mx-2" onClick={() => this.setTaskReportShow7(false)}>Ok</button>
      </div>
    </div>
        </>);
    }    

    appendNotes() {
        var newNote = `note-${this.state.notes.length}`;
        this.setState(prevState => ({ notes: prevState.notes.concat([newNote]) }));
      }  
      removeNotes = (deleteval) => {
        var notes = this.state.notes;
        var NotesBySalesRep = this.state.NotesBySalesRep;

        console.log(notes);
        console.log(deleteval);
        const index = deleteval;
        //  notes.indexOf(deleteval);
        console.log(index);

        if (index > -1) {
          notes.splice(index, 1);
          NotesBySalesRep.splice(index,1);

          var NotesBySalesRep = this.state.NotesBySalesRep;
          var errorArr = this.state.errorArr;
          let errorshow = 0;
  
          errorArr[index] = 0; 
          this.setState({
              errorArr,
          }, ()=>{
                  errorArr.forEach((item)=>{
                      errorshow = (item===1) ? 1 : errorshow;
                  });
          
                  if (errorshow===1) {
                      this.setState({
                          errorshow,
                          Messageerr : 'Note cannot be more than 500 characters.',
                          MessageerrType : "danger"
                      })
                  } else {
                      this.setState({
                          errorshow,
                          Messageerr : '',
                          MessageerrType : null
                      })
                  }
              })

        }
        this.setState({notes:[], NotesBySalesRep:[]}, ()=>{
            setTimeout(() => { this.setState({notes, NotesBySalesRep}); }, 500);

            
        });        
      }      

    scheduleSession = (Task, OppoDetails) =>{
        console.log(Task, OppoDetails);
        localStorage.setItem('Task', JSON.stringify(Task));
        localStorage.setItem('OppoDetails', JSON.stringify(OppoDetails));
        setTimeout(() => { window.location.href = "../scheduler?preset=true&&view=week"; }, 500);
        // window.history.replaceState(null, "Schedule Event", "../scheduler?preset=true")
    }
    scheduledSession = (Task, OppoDetails) =>{
        console.log("TASK DETAIL----",Task);
        localStorage.setItem('Task', JSON.stringify(Task));
        localStorage.setItem('OppoDetails', JSON.stringify(OppoDetails));
        setTimeout(() => { window.location.href = "../scheduler?view=week&date="+Task.session_start_date; }, 500);
    }

    render() {
        const {TasksListData, OppoDetails, user_id, user_role, editTaskReportShow, editTaskReportShow2, editTaskReportShow3, editTaskReportShow4, editTaskReportShow5, editTaskReportShow6, editTaskReportShow7} = this.state;

        return (
            <>
        <Modal 
            title="Task Report" 
            contentClasses="" 
            size="md" 
            onClose={() => this.setTaskReportShow(false)} 
            show={editTaskReportShow}>
                {this.editTaskReport()}
        </Modal> 
        <Modal 
            title="Task Report" 
            contentClasses="" 
            size="md" 
            onClose={() => this.setTaskReportShow2(false)} 
            show={editTaskReportShow2}>
                {this.editTaskReport2()}
        </Modal> 
        <Modal 
            title="Task Report" 
            contentClasses="" 
            size="md" 
            onClose={() => this.setTaskReportShow3(false)} 
            show={editTaskReportShow3}>
                {this.editTaskReport3()}
        </Modal> 
        <Modal 
            title="Task Report" 
            contentClasses="" 
            size="md" 
            onClose={() => this.setTaskReportShow4(false)} 
            show={editTaskReportShow4}>
                {this.editTaskReport4()}
        </Modal> 

        <Modal 
            title="" 
            contentClasses="" 
            size="md" 
            onClose={() => this.setTaskReportShow5(false)} 
            show={editTaskReportShow5}>
                {this.editTaskReport5()}
        </Modal> 
        <Modal 
            title="Task Report" 
            contentClasses="" 
            size="md" 
            onClose={() => this.setTaskReportShow6(false)} 
            show={editTaskReportShow6}>
                {this.editTaskReport6()}
        </Modal> 

        <Modal 
            title="" 
            contentClasses="" 
            size="md" 
            onClose={() => this.setTaskReportShow7(false)} 
            show={editTaskReportShow7}>
                {this.editTaskReport7()}
        </Modal> 

        <main className="main-contant opportunity-management-main">
            <div className="contents">
            <div className="mb-3 mb-lg-4 pl-15">
                    <div className="text-center d-sm-flexs align-items-center justify-content-center">
                    {(OppoDetails) ? (<>
                        <h3 className="title-head d-inline word-break-all mb-0 text-center">{OppoDetails.opp_name} Tasks </h3>
                        <span className={`status-tag status-tag-${(OppoDetails.status==0) ? `open` : (OppoDetails.status==1) ? ` color_green` : ` color_red`}  d-inline-block ml-sm-3 my-2 my-sm-0`}>{(OppoDetails.status==0) ? `Open` : (OppoDetails.status==1) ? `Closed-WON` : `Closed-LOST`}</span>
                        </>
                    ) : (
                        null
                    )}
                    </div>
                    {(user_role==3) ? (
                    (OppoDetails) ? 
                    (
                    <>
                            <div className="main-profile d-flex mt-2 justify-content-center align-items-center">
                                <div className="img-wrap">
                                    <img src={process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((OppoDetails.sales_rep_profile_pic_url !== "") && (OppoDetails.sales_rep_profile_pic_url !== null)) ? OppoDetails.sales_rep_profile_pic_url : 'default-avatar.png')} alt={OppoDetails.sales_rep_first_name+' '+OppoDetails.sales_rep_last_name}/>
                                </div>
                                <div className="ml-2">
                                    <div className="name f-500 mb-1">
                                        {OppoDetails.sales_rep_first_name+' '+OppoDetails.sales_rep_last_name}
                                    </div>
                                </div>
                            </div>  
                    </>
                    ) : (
                        null
                    )                                  
                ) : null}
                </div>


                <Link className="mt-0 mb-3 d-block  go-back d-flex align-items-center" to={'opportunities'}><i
                        className="iconmoon icon-chevron-left"></i> <span className="d-none d-sm-inline-block"> Go Back </span> </Link>
                <div className="accordion" id="accordionTable">

{(TasksListData && TasksListData.length>0) ? (
    TasksListData.map((Stages) => {
        var status = (Stages.stage_status === "0") ? 'Completed outside Core Ai' : ((Stages.stage_status==="1") ? 'In Progress' : ((Stages.stage_status==="2") ? 'Completed' : 'Future'));
        var icon = Stages.stage_icon_url;
        var iconImg = process.env.REACT_APP_STATIC_FILES_PATH + '/' + (((icon !== "") && (icon !== null) && (icon !=='null')) ? icon : 'default_sales_stage.png');

        return(
                    <div className="table-wrap p-3 p-xl-4 mb-4">
                        <div className="table-row d-sm-flex justify-content-between align-items-center">
                            <div className="d-sm-flex justify-content-between align-items-center">
                                <div className="mr-3 sell-icon-wrap2">
                                    <img src={iconImg} width="55" className="iconImg" alt={Stages.stage_name} />
                                </div>

                                <div className="pl-0 ml-0">
                                    <div className="org-name f2015 w-auto f-600">
                                        {Stages.stage_name}
                                    </div>
                                    <p className="f2015 mt-2 mb-0 word-break-all"> {Stages.stage_description}</p>
                                </div>
                            </div>
                            <div className="actions status-text  text-right">
                                <div className="f-600 color_blue">
                                    {status}
                                </div>
                                {(Stages.stage_status !== "0") ? (
                                    <button className="dropdown-toggle" type="button" data-toggle="collapse"
                                    data-target={`#collapse${Stages.stage_id}`} aria-expanded="false" aria-controls={`collapse${Stages.stage_id}`}>
                                        <i className="iconmoon icon-chevron-down"></i>
                                    </button>
                                ) : null}
                            </div>
                        </div>
                                        <div id={`collapse${Stages.stage_id}`} className="collapse" data-parent="#accordionTable">
                                        <div className="team-row my-3 p-3 p-lg-4">
                                            <div className="table-container pl-2">
                                                <div className="table-responsives">
                                                    <table className="table border-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Task</th>
                                                                <th></th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                        {(Stages.stage_status !== "0") ? (
                            (Stages.tasks && (Stages.tasks).length>0) ? (
                                Stages.tasks.map((Tasks) => {
                                    if (Tasks.status !== "0") {

                                    if (user_role==3){
                                        if (Tasks.task_status=="0"){
                                            var taskStatus = "Not Assigned";
                                            var taskColor = "not-assigned";
                                        } else if (Tasks.task_status=="1"){
                                            var taskStatus = "Assigned";
                                            var taskColor = "open";
                                        } else if (Tasks.task_status=="2"){
                                            var taskStatus = "Scheduled";
                                            var taskColor = "open";
                                        } else if (Tasks.task_status=="3"){
                                            var taskStatus = "Rejected";
                                            var taskColor = "rejected";
                                        } else if (Tasks.task_status=="5"){
                                            var taskStatus = "Completed With Sessions";
                                            var taskColor = "completed";
                                        } else if (Tasks.task_status=="6"){
                                            var taskStatus = "Awaiting Review";
                                            var taskColor = "panding";
                                        } else if (Tasks.task_status=="7"){
                                            var taskStatus = "Approved";
                                            var taskColor = "completed";
                                        }
                                    }   
                                    if (user_role==4){
                                        if (Tasks.task_status=="0"){
                                            var taskStatus = "Not Assigned";
                                            var taskColor = "not-assigned";
                                        } else if (Tasks.task_status=="1"){
                                            var taskStatus = "Assigned";
                                            var taskColor = "open";
                                        } else if (Tasks.task_status=="2"){
                                            var taskStatus = "Scheduled";
                                            var taskColor = "open";
                                        } else if (Tasks.task_status=="3"){
                                            var taskStatus = "Rejected";
                                            var taskColor = "rejected";
                                        } else if (Tasks.task_status=="5"){
                                            var taskStatus = "Completed With Sessions";
                                            var taskColor = "completed";
                                        } else if (Tasks.task_status=="6"){
                                            var taskStatus = "Completed";
                                            var taskColor = "completed";
                                        } else if (Tasks.task_status=="7"){
                                            var taskStatus = "Approved";
                                            var taskColor = "completed";
                                        }
                                    }                                        

                                    return(
                                                            <tr>
                                                                <td className="text-with-wrap"> 
                                                                {(Tasks.task_required=="1") ? (<i className="fa fa-asterisk" aria-hidden="true"></i>) : null}
                                                                 <span className="text d-inline-block">{Tasks.task_title}</span></td>
                                                                <td className="text-right"><span
                                                                        className={`status-tag status-tag-${taskColor} d-inline-block ml-3`}>{taskStatus}</span></td>
                                                                <td>
                                                                    <div className="actions">
                                                                        <div className="dropdown">
                                                                        {((user_role==3) && ( (Stages.stage_status==="1") || (Stages.stage_status==="2")) && (((Tasks.task_status=="0") && (OppoDetails.status==="0")) || (Tasks.task_status==="1") || (Tasks.task_status==="2") || (Tasks.task_status==="6") || (Tasks.task_status==="7") || (Tasks.task_status==="3"))) ? (<>
                                                                            
                                                                            <button className="btn dropdown-toggle" type="button"
                                                                                data-toggle="dropdown"
                                                                                aria-haspopup="true" aria-expanded="false">
                                                                                <i className="iconmoon icon-ellipse-v"></i>
                                                                            </button>
                                                                            <div className="dropdown-menu"
                                                                                aria-labelledby="">
                                                                                {/* {(Tasks.task_status=="6") ? (
                                                                                <a className="dropdown-item" onClick={()=>this.setTaskReportShow6(true, Tasks.id)}><i className="ri-file-chart-line"></i>View Report</a>
                                                                                ) : null} */}
                                                                            {((Tasks.task_status=="0") && (OppoDetails.status==="0")) ? (
                                                                                <a className="dropdown-item" onClick={() => this.setTaskReportShow6(true, Tasks.id)}  ><i className="ri-user-shared-line"></i> Assign</a>
                                                                            ) : null}
                                                                            {((Tasks.task_status=="6") || (Tasks.task_status=="7") || (Tasks.task_status=="1") || (Tasks.task_status==="3")) ? (
                                                                                <a className="dropdown-item" onClick={() => this.setTaskReportShow6(true, Tasks.id)}  ><i className="ri-file-chart-line"></i> View Report</a>
                                                                            ) : null}


                                                                                {/* <a className="dropdown-item" onClick={()=>this.setTaskReportShow6(true, Tasks.id)}>{taskStatus=="Not Assigned"?<> <i className="ri-user-shared-line"></i> Assign</> : <><i className="ri-file-chart-line"></i>View Report</>} </a> */}
                                                                                {((Tasks.task_status=="0") && (OppoDetails.status==="0")) ? (
                                                                                <a className="dropdown-item" onClick={() => this.scheduleSession(Tasks, OppoDetails)}><i className="ri-time-line"></i> Schedule</a>
                                                                                ): (Tasks.task_status=="2") ? ( <Link  onClick={() => this.scheduledSession(Tasks, OppoDetails)} className="dropdown-item" ><i className="ri-calendar-todo-line"></i> View Calendar</Link>) :  null}
                                                                                        </div>
                                                                                        </>
                                                                            
                                                                        ) : null}
                                                                        {(user_role==4) ? (
                                                                            <>
                                                                            {(((Stages.stage_status==="1") || (Stages.stage_status==="2")) && ((Tasks.task_status==="1") || (Tasks.task_status==="3") || (Tasks.task_status==="6")|| (Tasks.task_status==="7"))) ? (<>
                                                                            <button className="btn dropdown-toggle" type="button"
                                                                                data-toggle="dropdown"
                                                                                aria-haspopup="true" aria-expanded="false">
                                                                                <i className="iconmoon icon-ellipse-v"></i>
                                                                            </button>
                                                                            <div className="dropdown-menu" aria-labelledby="">
                                                                                <a className="dropdown-item" onClick={() => this.setTaskReportShow3(true, Tasks.id)}  ><i className="ri-file-chart-line"></i> View Report</a>
                                                                            {((Tasks.task_status=="1") || (Tasks.task_status=="3")) ? (
                                                                                <a className="dropdown-item" onClick={() => this.setTaskReportShow5(true, Tasks.id, Tasks.sales_rep_note_exists)}  ><i className="ri-checkbox-circle-line"></i> Mark Complete</a>
                                                                            ): null}
                                                                                {/* <a className="dropdown-item" onClick={() => this.setTaskReportShow5(true)}  ><i className="ri-checkbox-circle-line"></i> Mark Complete</a> */}
                                                                                </div>
                                                                            </>

                                                                            ) :null}                                                                                
                                                                        </>
                                                                            
                                                                        ) : null}                                                                        
                                                                        </div>
                                                                    </div>
            
                                                                </td>
                                                            </tr>
                                        )
                                    }
                                })
                                ) :null
                                
                                
                                ) :null}
                                
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>                                        

                    </div>

        );
    })
) :(``)}
                </div>
            </div>
        </main>
                
            </>
        )
    }
}
