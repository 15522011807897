import React, { useEffect, useRef } from 'react';
import './style.css';

function autosize(current) {
  const textareaToResize = current;
  const resizeTextarea = () => {
    textareaToResize.style.height = `${textareaToResize.scrollHeight}px`;
  };
  resizeTextarea();
  textareaToResize.addEventListener('input', resizeTextarea);
  return () => {
    textareaToResize.removeEventListener('input', resizeTextarea);
  };
}

export const DetailNotes = ({ onTextareaInput }) => {
  const textareaRef = useRef();
  const handleInputChange = (e) => {
    onTextareaInput(e.target.value);
  };
  useEffect(() => {
    const textarea = textareaRef.current;
    const lineHeight = parseFloat(window.getComputedStyle(textarea).lineHeight);
    textarea.style.height = `${lineHeight}px`;
    autosize(textarea);
  }, []);
  return (
    <div className="notes" onClick={(e) => e.stopPropagation()}>
      <textarea
        ref={textareaRef}
        className="placeholder w-100 border-0 bg-transparent"
        placeholder="Notes"
        autoFocus
        onChange={handleInputChange}
      ></textarea>
    </div>
  );
};
