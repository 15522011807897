import axios from "axios"
import React, { useState, useEffect } from "react"
import Loader from "react-js-loader";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV, faFilter } from '@fortawesome/free-solid-svg-icons';
import BootstrapTable from 'react-bootstrap-table-next';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import toast, { Toaster } from 'react-hot-toast';
import moment from "moment";
const Setting = () => {
    const [envState, setEnvState] = useState({ userName: "", password: "", securityToken: "" })
    const [loader, setLoader] = useState(false)
    const [users, setUsers] = useState([])
    const { SearchBar } = Search;
    const pagination = paginationFactory({
        hideSizePerPage: true,

    });

    const cronTimeformatter = (date) => {
        let dataTime = moment(date).format("DD MMM YYYY hh:mm A")
        return dataTime;
    }

    const columns = [{
        dataField: 'opp_count',
        text: 'New Opportunity Count',
        sort: true,
        sortCaret: (order, column) => {
            if (!order) return (<span> &nbsp;  </span>);
            else if (order === 'asc') return (<span> &nbsp;  <i className="fa fa-chevron-up active"></i></span>);
            else if (order === 'desc') return (<span> &nbsp; <i className="fa fa-chevron-down active"></i> </span>);
            return null;
        }
    }
        , {
        dataField: 'update_opp_count',
        text: 'Updated Opportunity Count',
        sort: true,
        sortCaret: (order, column) => {
            if (!order) return (<span> &nbsp;  </span>);
            else if (order === 'asc') return (<span> &nbsp;  <i className="fa fa-chevron-up active"></i></span>);
            else if (order === 'desc') return (<span> &nbsp; <i className="fa fa-chevron-down active"></i> </span>);
            return null;
        }
    },
    {
        dataField: 'user_count',
        text: 'User Count',
        sort: true,
        sortCaret: (order, column) => {
            if (!order) return (<span> &nbsp;  </span>);
            else if (order === 'asc') return (<span> &nbsp;  <i className="fa fa-chevron-up active"></i></span>);
            else if (order === 'desc') return (<span> &nbsp; <i className="fa fa-chevron-down active"></i> </span>);
            return null;
        }
    }, {
        dataField: 'cron_time',
        text: 'Cron Time',
        sort: true,
        formatter: cronTimeformatter,
        sortCaret: (order, column) => {
            if (!order) return (<span> &nbsp;  </span>);
            else if (order === 'asc') return (<span> &nbsp;  <i className="fa fa-chevron-up active"></i></span>);
            else if (order === 'desc') return (<span> &nbsp; <i className="fa fa-chevron-down active"></i> </span>);
            return null;
        }
    },
    ];
    const getEnvData = async () => {
        setLoader(true)
        await axios.get(`https://api.corecoach.io/api/v1/salesforce/getEnv`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                console.log(res, "lpi");
                setLoader(false)
                if (res.data.code == 200) {
                    setEnvState(res?.data?.data)
                }
            })
            .catch((error) => {
                setLoader(false)
                console.log(error)
            })
    }

    const getUserData = async () => {
        setLoader(true)
        await axios.get(`https://api.corecoach.io/api/v1/salesforce/get-cron`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                setUsers(res.data.data)
                setLoader(false)
                if (res.data.code == 200) {
                    setEnvState(res?.data?.data)
                }
            })
            .catch((error) => {
                setLoader(false)
                console.log(error)
            })
    }




    useEffect(() => {
        getEnvData()
        getUserData()
    }, []);

    const handleSetEnv = async () => {
        await axios.post(`https://api.corecoach.io/api/v1/salesforce/setEnv`, envState, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                setLoader(false)
                toasterValue('credentials are set in our env file')
                console.log(res, "lpi");
            })
            .catch((error) => {
                setLoader(false)
                console.log(error)
            })

    }

    const handleUsers = async () => {
        setLoader(true)
        await axios.get(`https://api.corecoach.io/api/v1/salesforce/matched/ownerId`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                setLoader(false)
                toasterValue('Sales force users mached and add in our Coreai DB')
                console.log(res, "lpi");
            })
            .catch((error) => {
                setLoader(false)
                console.log(error)
            })
    }

    const handleOpportunity = async () => {
        setLoader(true)
        await axios.get(`http://localhost:3001/api/v1/salesforce/set-opportunity`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            }
        })
            .then(res => {
                setLoader(false)
                toasterValue('All salesforce opportunity set in our core ai DB')
                console.log(res, "lpi");
            })
            .catch((error) => {
                setLoader(false)
                console.log(error)
            })
    }

    const toasterValue = (value) => {
        toast.success(value, {
            style: {
                backgroundColor: "#003366",
                border: '1px solid #003366',
                marginTop: "70px",
                marginLeft: "370px",
                padding: '16px',
                color: '#ffff',
            },
            iconTheme: {
                primary: '#ffff',
                secondary: '#003366',
            },
        });
    }
    return (


        <main className="main-contant">
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <div className="contents">
                <h3 className="title-head mb-3 mb-lg-4 ">Setting </h3>
                <div className="accordion" id="accordionTable">
                    <div className="clearfix px-2 form-head modal-body">
                        <div className="profile-info ">
                            <div className="row mt-4 mt-sm-0">
                                <div className="col form-group">
                                    <label htmlFor="first_name" className="mb-0">Sales Force User Name</label>
                                    <input type="text" className="form-control f-600 f2015" name="userName" defaultValue="" id="first_name" value={envState?.userName} onChange={(e) => setEnvState({ ...envState, userName: e.target.value })}
                                        placeholder="Sales Force User Name" />
                                    {/* <span className='error'>kjlkld</span>  */}
                                </div>
                                <div className="col form-group">
                                    <label htmlFor="last_name" className="mb-0">Sales Force Password</label>
                                    <input type="text" className="form-control f-600 f2015" value={envState?.password} name="password" defaultValue="" id="last_name" placeholder="Sales Force Password" onChange={(e) => setEnvState({ ...envState, password: e.target.value })} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="mb-0">Sales Force  Security Token</label>
                                <input type="email" name="securityToken" id="securityToken" className="form-control f-600 f2015" vdefaultValue=""
                                    placeholder="Security Token" value={envState?.securityToken} onChange={(e) => setEnvState({ ...envState, securityToken: e.target.value })} />
                            </div>
                            <div className="form-group text-center">
                                {loader
                                    ? <Loader type="bubble-scale" bgColor={"#003366"} size={100} />
                                    : <>
                                        <button type="button" className="btn dark-btn py-0 px-4 mr-2" onClick={() => handleSetEnv()}>Set Creds</button>
                                        <button type="button" className="btn dark-btn py-0 px-4 mr-2" onClick={() => handleUsers()}>Fetch Users</button>
                                        <button type="button" className="btn dark-btn py-0 px-4 mr-2" onClick={() => handleOpportunity()}>Fetch Opportunity</button>

                                    </>}


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToolkitProvider
                keyField="id"
                data={users}
                columns={columns}
                search
            >
                {
                    props => (
                        <div>
                            <div className="d-flex flex-wrap justify-content-between mb-3">

                                <h3 className="title-head mt-0 mb-2 ml-3">Salesforce Cron Data</h3>

                                <div className="right-content right-search-content flex-wrap  d-flex  justify-content-between align-items-center">

                                    <div className="search-bar ml-2 mr-3">
                                        <SearchBar {...props.searchProps} />

                                    </div>
                                </div>
                            </div>

                            <BootstrapTable
                                {...props.baseProps}
                                pagination={pagination} />
                        </div>
                    )
                }
            </ToolkitProvider>

        </main>

    )
}

export default Setting;