import React, { Component } from 'react'
import './sidebar.css';
import axios from '../../apiConnection/axios';

import {
  Link
} from "react-router-dom";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationclass: "",
      orgStructureLink: "./organization-structure",
      userManagementLink: "./user-management",
      salesProcessLink: "./initiate-sales-process",
    };
    this.changeActive = this.changeActive.bind(this);
    this.getsalesRep = this.getsalesRep.bind(this);
    this.getOrganizations = this.getOrganizations.bind(this);
    this.getsalesProcess = this.getsalesProcess.bind(this);
  }
  componentDidMount() {
    this.getsalesProcess();
    //destructuring pathname from location
    const { pathname } = window.location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    console.log(splitLocation[2]);
    if (splitLocation[2] === 'dashboard') { this.setState({ locationclass: 1 }) }
    else if ((splitLocation[2] === 'initiate-organization-structure') || (splitLocation[2] === 'organization-structure') || (splitLocation[2] === 'inactive-organization-structure')) { this.setState({ locationclass: 2 }) }
    else if ((splitLocation[2] === 'initiate-user-management') || (splitLocation[2] === 'user-management')) { this.setState({ locationclass: 3 }) }
    else if ((splitLocation[2] === 'initiate-sales-process') || (splitLocation[2] === 'sales-process') || (splitLocation[2] === 'inactive-sales-stages')) { this.setState({ locationclass: 4 }) }
    else { this.setState({ locationclass: 5 }) }
    setTimeout(() => { this.changeActive(this.state.locationclass); }, 500);


    this.getsalesRep();
    this.getOrganizations();
  }

  componentWillReceiveProps(nextProps) {
    const { pathname } = window.location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    console.log(splitLocation[2]);
    if (splitLocation[2] === 'dashboard') { this.setState({ locationclass: 1 }) }
    else if ((splitLocation[2] === 'initiate-organization-structure') || (splitLocation[2] === 'organization-structure') || (splitLocation[2] === 'inactive-organization-structure')) { this.setState({ locationclass: 2 }) }
    else if ((splitLocation[2] === 'initiate-user-management') || (splitLocation[2] === 'user-management')) { this.setState({ locationclass: 3 }) }
    else if ((splitLocation[2] === 'initiate-sales-process') || (splitLocation[2] === 'sales-process') || (splitLocation[2] === 'inactive-sales-stages')) { this.setState({ locationclass: 4 }) }
    else { this.setState({ locationclass: 5 }) }
    setTimeout(() => { this.changeActive(this.state.locationclass); }, 500);

  }


  getsalesRep = async () => {
    await axios.get(`users/check-sales-rep-exists`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res)
        // if (res.data.code === 201) {
        //   this.setState({userManagementLink : "./initiate-user-management"})
        //   }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  getsalesProcess = async () => {
    console.log("Sales Process");

    await axios.get(`sales-process/get-stages-and-tasks`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        if (res.data.code == 200) {
          console.log("Sales Process", res.data.data.length);
          if (res.data.data.length > 0) {
            this.setState({ salesProcessLink: "./sales-process" })
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }


  getOrganizations = async () => {
    await axios.get(`organizations/get-organization-structure`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then(res => {
        console.log(res.data.data.length);
        if (res.data.data.length <= 0) {
          this.setState({ orgStructureLink: "./initiate-organization-structure" })
          // window.location.href = "./organization-structure";
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  changeActive = (link) => {
    console.log(link);

    if (link === 1) { this.setState({ locationclass: 1 }) }
    else if (link === 2) { this.setState({ locationclass: 2 }) }
    else if (link === 3) { this.setState({ locationclass: 3 }) }
    else if (link === 4) { this.setState({ locationclass: 4 }) }
    else if (link === 5) { this.setState({ locationclass: 5 }) }
    else { this.setState({ locationclass: 6 }) }
  }

  render() {
    const { locationclass, orgStructureLink, userManagementLink, salesProcessLink } = this.state;

    return (
      <>
        <aside>
          <div className="container position-relative">
            <div className="sidebar">
              <span className="color_blue f2015 f-600 text-right d-block d-lg-none toggler"><i className="fa fa-times text-white"></i></span>
              <ul className="list-unstyled">
                <li>
                  <Link onClick={() => this.changeActive(1)} to={'./dashboard'} className={locationclass === 1 ? "active f2015" : "f2015"}>Dashboard</Link>
                </li>
                <li>
                  <Link onClick={() => this.changeActive(2)} to={`${orgStructureLink}`} className={locationclass === 2 ? "active f2015" : "f2015"} >Organization Setup</Link>
                </li>
                <li>
                  <Link onClick={() => this.changeActive(3)} to={`${userManagementLink}`} className={locationclass === 3 ? "active f2015" : "f2015"} >User Management</Link>
                </li>
                <li>
                  <Link onClick={() => this.changeActive(4)} to={`${salesProcessLink}`} className={locationclass === 4 ? "active f2015" : "f2015"}>Sales Process</Link>
                </li>
                <li>
                  <Link onClick={() => this.changeActive(5)} to={`${"/setting"}`} className={locationclass === 5 ? "active f2015" : "f2015"}>Setting</Link>
                </li>


              </ul>
              <ul className="list-unstyled mb-0 bottom-list">
                <li className="border-bottom">
                  <a href="javascript:;" className="f2015 versions"><small>Version 1.0.1.2</small></a>
                </li>
                <li>

                  <Link to={`/logout`} className="f2015">Log Out</Link>
                </li>
              </ul>
            </div>
          </div>
        </aside>
      </>
    )
  }
}
