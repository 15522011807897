import React, { useState } from 'react';
import './style.css';
import axios from '../apiConnection/axios';

export const FeedbackModal = ({
  task_title,
  task_id,
  transcript,
  sales_manager_id,
  sales_rep_id,
  onClose,
  feedbackSubmitted,
  setfeedbackSubmitted,
}) => {
  const [reason, setReason] = useState('');
  const [details, setDetails] = useState('');
  const [errorOccured, setErrorOccured] = useState(false);

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };
  const handleDetailsChange = (event) => {
    setDetails(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      task_id,
      task_title,
      transcript,
      sales_manager_id,
      sales_rep_id,
      declineReason: reason,
      declineDetails: details,
    };
    try {
      const response = await axios.post(`opportunity/decline-task`, data);
      // console.log(response.data);
      if (response.data.rows_affected && response.data.rows_affected === 1) {
        setfeedbackSubmitted(true);
      } else {
        setErrorOccured(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className="modal d-flex justify-content-center align-items-center "
      onClick={onClose}
    >
      <div
        className="feedback-modal-box modal-box"
        onClick={(e) => e.stopPropagation()}
      >
        {errorOccured && (
          <div className="submit-error text-center text-white bg-danger p-2 mb-3">
            There was an issue submitting the feedback
          </div>
        )}

        <div className="d-flex flex-row justify-content-between align-items-start flex-nowrap">
          <div className="feedback-modal-header h4 mb-3">Feedback</div>
          <button className="close-button border-0" onClick={onClose}>
            X
          </button>
        </div>
        <div className="h5">Task</div>
        <div className="feedback-modal-task mb-3">{task_title}</div>
        <div className="h5">Reason Declined</div>
        <form onSubmit={handleSubmit}>
          <select
            className="feedback-modal-decline-reason rounded w-100 py-1 mb-3"
            value={reason}
            onChange={handleReasonChange}
            required
            disabled={feedbackSubmitted}
          >
            <option value="">Select a reason</option>
            <option value="Not Relevant">Not Relevant</option>
            <option value="Reason 2">Reason 2</option>
            <option value="Reason 3">Reason 3</option>
          </select>

          <div className="h5 pb-2">Please provide details:</div>
          <textarea
            className="feedback-modal-decline-details rounded w-100 p-2 mb-3"
            placeholder="Why did you decline this task?"
            value={details}
            onChange={handleDetailsChange}
            required
            disabled={feedbackSubmitted}
          ></textarea>
          <div className="feedback-modal-buttons d-flex justify-content-end">
            {feedbackSubmitted && <div>This feedback has been submitted!</div>}
            <button
              className="cancel-button rounded p-2"
              onClick={onClose}
              disabled={feedbackSubmitted}
            >
              Cancel
            </button>
            <button
              className="submit-button rounded p-2"
              disabled={feedbackSubmitted}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
