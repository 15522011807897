import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ActionItem } from './ActionItem/ActionItem';
import './style.css';
import axios from '../apiConnection/axios';
const ActionItems = ({
  sales_manager_id,
  sales_rep_name,
  sales_rep_id,
  actionItems,
}) => {
  const [actionItemsData, setActionItemsData] = useState(actionItems);
  const [actionItemsChanged, setActionItemsChanged] = useState(false);
  const [didAssignTask, setAssignTask] = useState(false);
  const [assignedTaskCounter, setAssignedTaskCounter] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const assignTask = async (item, sales_manager_id) => {
    setAssignTask(true);
    setAssignedTaskCounter((prevCounter) => prevCounter + 1);
    const response = await axios.post(`opportunity/assign-task`, {
      task_id: item.task_id,
      sales_manager_id: sales_manager_id,
      assigned_date: new Date(),
      excpected_due_date: item.dueDate,
      note: item.notes,
    });
    console.log('Assign Task response: ', response.data);
  };

  const isItemValid = (item) => {
    if (!item.dueDate) {
      setModalMessage(`Please set the due date for task "${item.task_title}".`);
      setShowModal(true);
      return false;
    }
    if (!item.notes) {
      setModalMessage(`Please include notes for task "${item.task_title}".`);
      setShowModal(true);
      return false;
    }
    return true;
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleAssignClick = async () => {
    if (actionItemsChanged) {
      setAssignedTaskCounter(0);
      for (const item of actionItemsData) {
        if (item.toggle_state === 'on' && isItemValid(item)) {
          await assignTask(item, sales_manager_id);
        }
      }
    } else {
      window.alert('You tried to click on assign without assigning anything.');
    }
  };
  const updateItem = (item, index) => {
    const newActionItemsData = [...actionItemsData];
    newActionItemsData[index] = item;
    setActionItemsData(newActionItemsData);
    setActionItemsChanged(true);
  };
  return (
    <div className="ActionItems cardWrap mb-3">
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Validation Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="form-head mb-3 f2015  clearfix">
        <span className="bg-white d-inline-block f-600 pr-3">Action Items</span>
      </div>
      <div className="items-header mb-2">
        <div className="col-tasks-header">Task</div>
        <div className="assignToggle-header">Assign Status</div>
      </div>
      <div className="items-rows">
        {actionItemsData &&
          actionItemsData.map((item, index) => (
            <ActionItem
              item={item}
              sales_manager_id={sales_manager_id}
              sales_rep_id={sales_rep_id}
              sales_rep_name={sales_rep_name}
              onItemChange={(item) => updateItem(item, index)}
              key={index}
            />
          ))}
      </div>
      <div className="items-footer d-flex flex-row justify-content-between align-items-center flex-nowrap">
        {didAssignTask && (
          <div>
            {assignedTaskCounter} task
            {`${assignedTaskCounter > 1 ? 's' : ''}`} assigned to{' '}
            {sales_rep_name}.
          </div>
        )}
        <button
          onClick={handleAssignClick}
          className={`assign-button rounded border-0 m-2 ${
            !actionItemsChanged ? 'disabled' : ''
          }`}
          disabled={!actionItemsChanged}
        >
          <i className="ri-user-shared-line"></i>
          Assign
        </button>
      </div>
    </div>
  );
};
ActionItems.propTypes = {
  actionItems: PropTypes.arrayOf(
    PropTypes.shape({
      task_title: PropTypes.string.isRequired,
      task_id: PropTypes.string.isRequired,
      transcript: PropTypes.arrayOf(
        PropTypes.shape({
          timestamp: PropTypes.string,
          transcript: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
};
export default ActionItems;
