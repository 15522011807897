import { React, useState } from 'react';
import './style.css';
import { DeclineButton } from '../../icons/DeclineButton';
import infoIcon from '../../images/info.png';
import { DateWrapper } from '../DateWrapper/DateWrapper';
import axios from '../apiConnection/axios';
export const ActionItemDetailModal = ({
  onClose,
  openFeedbackModalAndCloseDetailModal,
  task_title,
  coNNIEScore,
  transcript,
  task_id,
  notes,
  onTextareaInput,
  sales_manager_id,
  sales_rep_name,
}) => {
  const [dueDate, setDueDate] = useState(new Date());
  const [isTaskAssigned, setisTaskAssigned] = useState(false);
  const handleNotes = (e) => {
    onTextareaInput(e.target.value);
  };
  const handleDateClicked = (e) => {
    e.stopPropagation();
  };
  const handleDatePicked = (date) => {
    setDueDate(date);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!dueDate) {
      window.alert(`Please set the due date for task "${task_title}".`);
      return;
    }
    if (!notes) {
      window.alert(`Please include notes for task "${task_title}".`);
      return;
    }
    try {
      const response = await axios.post('opportunity/assign-task', {
        task_id: task_id,
        sales_manager_id: sales_manager_id,
        assigned_date: new Date(),
        excpected_due_date: dueDate,
        notes: notes,
      });
      setisTaskAssigned(true);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div
        className="modal d-flex justify-content-center align-items-center"
        onClick={onClose}
      >
        <div className="modal-box" onClick={(e) => e.stopPropagation()}>
          <div className="task-score-section d-flex flex-row flex-nowrap align-items-start justify-content-between mb-3">
            <div className="">
              <div className="h4">Detected Task:</div>
              <div className="">{task_title}</div>
            </div>
            {coNNIEScore && (
              <div className="d-flex flex-row flex-wrap align-items-center justify-content-around">
                <div className="h4 text-nowrap flex-full-row text-center m-0">
                  CoNNIE Score:
                </div>
                <div className="">{coNNIEScore}</div>
                <img
                  src={infoIcon}
                  className="infoIcon"
                  alt="Info button to display tooltip about CoNNIE Score"
                  title="CoNNIE Score is calculated by calculating the exact words that match in the transcript and task."
                />
              </div>
            )}
          </div>
          <div className="transcript-section mb-3">
            <div className="h4">Transcript Section: </div>

            {transcript &&
              transcript.map((item, index) => (
                <div
                  className="transcript-chunk d-flex flex-row align-items-start h7 my-3"
                  key={index}
                >
                  {item.timestamp && (
                    <div className="timestamp h-fit p-1 mr-5">
                      {item.timestamp}
                    </div>
                  )}
                  <div className="transcript-text">{item.transcript}</div>
                </div>
              ))}
          </div>
          <form onSubmit={handleSubmit}>
            <textarea
              className="notes-section rounded w-100 p-2 mb-3"
              placeholder="notes for the sales rep"
              value={notes ? notes : ''}
              onChange={handleNotes}
            />
            <div className="buttons-section d-flex flex-row justify-content-between align-items-end">
              <DateWrapper
                onClick={handleDateClicked}
                handleDatePicked={handleDatePicked}
                dueDate={dueDate}
              />
              <div className="assign-decline d-flex flex-row align-items-center gap-2">
                {isTaskAssigned ? (
                  <div>Task assigned to {sales_rep_name}</div>
                ) : null}
                <button className="assign-button rounded border border-secondary d-flex align-items-center p-2">
                  Assign
                </button>
                <DeclineButton
                  className="p-2"
                  openModal={openFeedbackModalAndCloseDetailModal}
                  height="40"
                  width="40"
                  hoverable="true"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
