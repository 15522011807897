import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from '../sales_rep/modals/modal';
import { ReactMic } from 'react-mic';
import moment from 'moment';
import ActionItems from '../ActionItems/ActionItems';
import sampleActionItems from '../ActionItems/sampleActionItems.json';

import axios from '../apiConnection/axios';
import Utility from '../../components/utility';
var FormData = require('form-data');

const utilityObj = new Utility();

export default class taskDetail extends Component {
  constructor(props) {
    super(props);
    var event_sesion_id;
    if (
      this.props.match.params.sesion_id != '' &&
      this.props.match.params.sesion_id
    ) {
      event_sesion_id = this.props.match.params.sesion_id;
    }
    this.state = {
      isActive: false,
      isPaused: false,
      time: '',
      interval: null,
      loading: true,
      editTaskReportShow: false,
      toggleSwitch: false,
      toggleSwitch2: false,
      toggleSwitch3: false,
      ButtonHideA: false,
      ButtonHideB: false,
      ButtonHideC: false,
      hasActionItems: false,
      actionItems: [],
      AISummary: '',
      selectedButton: 'Summary',
      notes: [],
      notesType: 'voice',
      startDate: new Date('2021-06-09'),
      endDate: new Date('2021-06-09'),
      defaultStageSelectText: 'Prospect',
      showRejectionNote: false,
      SetOppoDetails: '',
      event_session_detail: '',
      event_sesion_id: event_sesion_id,
      stageList: [
        { id: 1, name: 'Prospect' },
        { id: 2, name: 'Qualify' },
        { id: 3, name: 'Develop' },
        { id: 4, name: 'Validate' },
      ],
      technical_notes: [],
      analyticalnotebody: '',
      analyticalNotes: '',
      eventData: [],
      forsalesrepnotebody: '',
      forsalesrepNotes: '',
      session_survey: [],
      session_survey_status: '',
      rep_session_survey_status: '',
      rep_session_survey: [],
    };

    this.updateBlob = this.updateBlob.bind(this);
  }

  componentDidMount = () => {
    utilityObj.loaderView('show');
    var full_name = localStorage.getItem('full_name');
    const user_id = localStorage.getItem('userid');
    const user_role = localStorage.getItem('adminsrToken');
    const profileImg = localStorage.getItem('profilePic');
    this.getSessionEventDetail();
    this.getSessionEventInternalNotes();
    this.setState(
      { profileImg, salesRepName: full_name, user_role, user_id: user_id },
      () => {
        if (user_role === '4') {
        } else if (user_role === '3') {
        }

        this.getSurvey();
        this.getSalesRepSurvey();
      },
    );
  };
  componentDidUpdate() {
    if (
      this.props.match.params.sesion_id != '' &&
      this.props.match.params.sesion_id
    ) {
      if (
        this.props.match.params.sesion_id == 'opportunities' ||
        this.props.match.params.sesion_id == 'dashboard' ||
        this.props.match.params.sesion_id == 'reports' ||
        this.props.match.params.sesion_id == 'coaching' ||
        this.props.match.params.sesion_id == 'calendar' ||
        this.props.match.params.sesion_id == 'myprofile'
      ) {
        window.location = '../' + this.props.match.params.sesion_id;
      }
    }
  }

  getSurvey = async (survey_for) => {
    const { user_id, event_sesion_id } = this.state;
    var survey_for;
    if (this.state.user_role == 3) {
      survey_for = 'sales_manager';
    } else {
      survey_for = 'sales_rep';
    }

    const payload = {
      session_id: event_sesion_id,
      survey_for: survey_for,
      user_id: user_id,
    };
    await axios
      .post(`survey/get-survey-progress-by-user`, payload, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          this.setState({
            session_survey_status: res.data.data[0].survey_status,
          });
          this.setState({ session_survey: res.data.data[0].survey });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getSalesRepSurvey = async () => {
    const { event_session_detail, event_sesion_id } = this.state;
    var survey_for = 'sales_rep';
    if (event_session_detail && event_session_detail.resourceId) {
      var sales_rep_id = event_session_detail.resourceId;

      const payload = {
        session_id: event_sesion_id,
        survey_for: survey_for,
        user_id: sales_rep_id,
      };
      await axios
        .post(`survey/get-survey-progress-by-user`, payload, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            utilityObj.loaderView('hide');

            this.setState({
              rep_session_survey_status: res.data.data[0].survey_status,
            });
            this.setState({ rep_session_survey: res.data.data[0].survey });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  getSalesManagerNotes = async () => {
    const { user_id, SetOppoDetails } = this.state;
    const payload = {
      session_id: SetOppoDetails.id,
      user_id: user_id,
      note_for: 'ANALYTICAL',
    };
    await axios
      .post(`coaching/get-coaching-session-notes`, payload, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          this.setState({
            analyticalnotebody: res.data.data[0].note_body,
            analyticalNotes: res.data.data,
          });
          console.log(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getSalesManagerNotesForRep = async () => {
    const { user_id, event_sesion_id } = this.state;
    const payload = {
      session_id: event_sesion_id,
      user_id: user_id,
      note_for: 'SALESREP',
    };
    await axios
      .post(`coaching/get-coaching-session-notes`, payload, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        },
      })
      .then((res) => {
        // console.log(`get-coaching-session-notes: ${JSON.stringify(res)}`);
        if (res.data.code === 200 && res.data.data.length > 0) {
          this.setState({
            noteforsalesrepbody: res.data.data[0].note_body,
            Notesforsalesrep: res.data.data,
          });
          console.log(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  startRecording = () => {
    const { isPaused } = this.state;
    if (isPaused !== true) {
      this.setState(
        {
          time: 0,
        },
        () => {
          this.setState({ record: true }, () => {
            this.watchUpdate(true);
          });
        },
      );
    } else {
      this.setState({ record: true, isPaused: false }, () => {
        this.watchUpdate(true);
      });
    }
  };

  pauseRecording = (value) => {
    this.watchPause(value);
  };

  stopRecording = (recordedBlob) => {
    this.setState({ record: false });
    this.watchUpdate(false);
    console.log('recordedBlob onstop recording is: ', recordedBlob);
  };

  onData = (recordedBlob) => {
    console.log('chunk of real-time data is: ', recordedBlob);
  };

  getBlob = async (blob_url) => {
    var get_blob = await fetch(blob_url).then((r) => r.blob());
    console.log('BLOB_DATA', get_blob);
    this.updateBlob(get_blob);
  };

  onStop = (recordedBlob) => {
    console.log('recordedBlob is: ', recordedBlob);

    const file2 = new File([recordedBlob.blob], 'testfile.wav', {
      type: 'audio/wav',
    });

    var recordedBlob1 = file2;
    this.updateBlob(recordedBlob1);
  };

  updateBlob = (recordedBlob) => {
    this.setState({ recordedBlob }, () => {
      this.saveNote(1);
    });
  };
  setNotesType = (newValue) => {
    this.setState({ notesType: newValue });
  };

  setAddNotes = (newValue, type) => {
    const { SetOppoDetails, event_sesion_id } = this.state;
    this.setState({ recordedBlob: '' }, () => {
      this.setState({
        time: 0,
        addNotesShow: newValue,
        notesType: type,
        AddNoteTitle: SetOppoDetails.title,
        AddNoteID: event_sesion_id,
      });
    });
  };

  timerLoad = () => {
    if (this.state.isActive && this.state.isPaused === false) {
      this.state.interval = setInterval(() => {
        this.setState({
          time: parseInt(this.state.time + 1000),
        });
      }, 1000);
    } else {
      clearInterval(this.state.interval);
    }
  };

  watchUpdate = (newValue) => {
    this.setState(
      {
        isActive: newValue,
      },
      () => {
        this.timerLoad();
      },
    );
  };

  watchPause = (newValue) => {
    this.setState(
      {
        isPaused: newValue,
      },
      () => {
        this.timerLoad();
      },
    );
  };

  addNotes = () => {
    const {
      record,
      isPaused,
      time,
      ButtonHideA,
      notebody,
      MessageNotes,
      MessageTypeNotes,
      AddNoteTitle,
      AddNoteID,
      recordedBlob,
      notesType,
    } = this.state;
    return (
      <>
        <form className="">
          {MessageNotes && MessageNotes !== null ? (
            <div className={`alert alert-${MessageTypeNotes}`}>
              {MessageNotes}
            </div>
          ) : (
            ``
          )}
          <div className="form-group px-2">
            <div className="feild-text py-3 f-600 f2015 text-center">
              {AddNoteTitle}
            </div>
          </div>
          <div className="form-group px-2">
            <div className="select-voice-notes d-flex  p-2 mx-auto">
              <span
                className={
                  notesType === 'voice' ? `active text-center` : `text-center`
                }
                onClick={() => this.setNotesType('voice')}
              >
                <i className="ri-mic-2-line"></i> Voice
              </span>
              <span
                className={
                  notesType === 'text' ? `active text-center` : `text-center`
                }
                onClick={() => this.setNotesType('text')}
              >
                <i className="ri-list-unordered"></i> Text
              </span>
            </div>
          </div>

          {notesType === 'voice' ? (
            <>
              <div className="form-group px-2 pt-3 text-center">
                {record == true && isPaused == false ? (
                  <>
                    <div className="voice-wrap mx-auto">
                      <div
                        className="show-voice-blue mx-auto"
                        onClick={() => this.stopRecording()}
                      >
                        <i className="ri-stop-fill text-white ri-3x"></i>
                      </div>
                    </div>
                    <label className="mb-0 mt-3">Click to Stop</label>
                  </>
                ) : (
                  <>
                    <div className="voice-wrap mx-auto ">
                      {isPaused == true ? (
                        <div
                          className="show-voice mx-auto "
                          onClick={() => this.pauseRecording(false)}
                        >
                          <i className="ri-mic-fill text-white ri-3x"></i>
                        </div>
                      ) : (
                        <div
                          className="show-voice mx-auto "
                          onClick={() => this.startRecording()}
                        >
                          <i className="ri-mic-fill text-white ri-3x"></i>
                        </div>
                      )}
                    </div>
                    <label className="mb-0 mt-3">
                      {isPaused == true ? `Click to Resume` : `Click to Record`}
                    </label>
                  </>
                )}
              </div>
              <div className="form-group px-2 text-center">
                <div className="voice-time mb-4 color_blue">
                  <span className="digits">
                    {('0' + Math.floor((time / 600000) % 60)).slice(-2)}:
                  </span>
                  <span className="digits">
                    {('0' + Math.floor((time / 60000) % 60)).slice(-2)}.
                  </span>
                  <span className="digits mili-sec">
                    {('0' + Math.floor((time / 1000) % 60)).slice(-2)}
                  </span>
                </div>

                <div style={{ maxWidth: '100%', overflow: 'hidden' }}>
                  <ReactMic
                    mimeType="audio/wav"
                    pause={this.state.isPaused}
                    visualSetting="frequencyBars"
                    record={this.state.record}
                    className="sound-wave"
                    onStop={this.onStop}
                    onData={this.onData}
                    strokeColor="#f5aba7"
                    backgroundColor="#FFFFFF"
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="form-group">
                <label className="mb-0">Notes</label>
                <textarea
                  className="form-control f-600 f2015"
                  rows={2}
                  maxRows={2}
                  id={`notebody`}
                  defaultValue={notebody}
                  onChange={(e) => this.changeFunction(e)}
                  placeholder="Click to add notes."
                ></textarea>
              </div>
            </>
          )}
          {notesType === 'voice' && record != true && recordedBlob != '' ? (
            <></>
          ) : null}
          {notesType === 'text' && ButtonHideA !== true ? (
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn dark-btn  cancel-btn mx-2"
                onClick={() => this.saveNote(1)}
              >
                Save
              </button>
              <button
                type="button"
                className="btn light-btn  cancel-btn mx-2"
                onClick={() => this.setAddNotes(false)}
              >
                Cancel
              </button>
            </div>
          ) : null}
        </form>
      </>
    );
  };

  changeFunction = (e) => {
    if (e.target.value.trim().length == 0) {
      e.target.value = '';
    }
    var s = e.target.value;
    var keyid = e.target.id;

    if (keyid == 'notebody') {
      if (e.target.value.length > 500) {
        this.setState(
          (prevState) => ({
            ...prevState,
            MessageNotes: 'Note should be max 500 characters.',
            MessageTypeNotes: 'danger',
            ButtonHideA: true,
          }),
          () => {
            this.setState((prevState) => ({
              ...prevState,
              notebody: s,
            }));
          },
        );
      } else {
        this.setState(
          (prevState) => ({
            ...prevState,
            MessageNotes: null,
            MessageTypeNotes: null,
            ButtonHideA: false,
          }),
          () => {
            this.setState((prevState) => ({
              ...prevState,
              notebody: s,
            }));
          },
        );
      }
    }

    if (keyid == 'analyticalnotebody') {
      if (e.target.value.length > 1000) {
        this.setState(
          (prevState) => ({
            ...prevState,
            MessageAnalyticalNotes: 'Note should be max 1000 characters.',
            MessageAnalyticalTypeNotes: 'danger',
            ButtonHideB: true,
          }),
          () => {
            this.setState((prevState) => ({
              ...prevState,
              analyticalnotebody: s,
            }));
          },
        );
      } else {
        this.setState(
          (prevState) => ({
            ...prevState,
            MessageAnalyticalNotes: null,
            MessageAnalyticalTypeNotes: null,
            ButtonHideB: false,
          }),
          () => {
            this.setState((prevState) => ({
              ...prevState,
              analyticalnotebody: s,
            }));
          },
        );
      }
    }

    if (keyid == 'noteforsalesrepbody') {
      if (e.target.value.length > 1000) {
        this.setState(
          (prevState) => ({
            ...prevState,
            MessageForSalesRepNotes: 'Note should be max 1000 characters.',
            MessageForSalesRepTypeNotes: 'danger',
            ButtonHideC: true,
          }),
          () => {
            this.setState((prevState) => ({
              ...prevState,
              noteforsalesrepbody: s,
            }));
          },
        );
      } else {
        this.setState(
          (prevState) => ({
            ...prevState,
            MessageForSalesRepNotes: null,
            MessageForSalesRepTypeNotes: null,
            ButtonHideC: false,
          }),
          () => {
            this.setState((prevState) => ({
              ...prevState,
              noteforsalesrepbody: s,
            }));
          },
        );
      }
    }
  };

  saveNote = async (note_for) => {
    const {
      notebody,
      user_id,
      AddNoteID,
      notesType,
      recordedBlob,
      analyticalnotebody,
      noteforsalesrepbody,
      event_sesion_id,
      SetOppoDetails,
    } = this.state;
    var note_for =
      note_for === 1 ? 'INTERNAL' : note_for === 2 ? 'ANALYTICAL' : 'SALESREP';
    var note_content_type = notesType === 'voice' ? '1' : '0';
    utilityObj.loaderView('show');
    let payload, headers;

    if (note_for === 'INTERNAL') {
      if (note_content_type == '1') {
        payload = new FormData();
        payload.append('file', recordedBlob);

        headers = {
          'Content-Type': 'multipart/form-data',
          is_audio_note: 'AUDIO',
          session_id: AddNoteID,
          note_content_type: note_content_type,
          note_type: 1,
          user_id: user_id,
          note_for: note_for,
        };
      } else {
        payload = {
          session_id: AddNoteID,
          note_content_type: note_content_type,
          note_type: 1,
          user_id: user_id,
          note_body: notebody,
          note_for: note_for,
        };
        headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          is_audio_note: '',
        };
      }
    } else if (note_for === 'ANALYTICAL') {
      payload = {
        session_id: event_sesion_id,
        note_content_type: '0',
        note_type: 1,
        user_id: user_id,
        note_body: analyticalnotebody,
        note_for: note_for,
      };
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        is_audio_note: '',
      };
    } else if (note_for === 'SALESREP') {
      payload = {
        session_id: event_sesion_id,
        note_content_type: '0',
        note_type: 1,
        user_id: user_id,
        note_body: noteforsalesrepbody,
        note_for: note_for,
      };
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        is_audio_note: '',
      };
    }

    await axios
      .post(`coaching/save-coaching-session-notes`, payload, {
        headers,
      })
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          if (note_for === 'INTERNAL') {
            this.setState(
              {
                MessageNotes: 'Note updated successfully.',
                MessageTypeNotes: 'success',
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    MessageNotes: '',
                    MessageTypeNotes: null,
                    notebody: '',
                    recordedBlob: '',
                  });
                  this.setAddNotes(false);
                  this.componentDidMount();
                }, 3000);
              },
            );
          } else if (note_for === 'ANALYTICAL') {
            this.setState(
              {
                MessageAnalyticalNotes: 'Note updated successfully.',
                MessageAnalyticalTypeNotes: 'success',
                toggleSwitch2: false,
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    MessageAnalyticalNotes: '',
                    MessageAnalyticalTypeNotes: null,
                  });
                  this.componentDidMount();
                }, 3000);
              },
            );
          } else if (note_for === 'SALESREP') {
            this.setState(
              {
                MessageForSalesRepNotes: 'Note updated successfully.',
                MessageForSalesRepTypeNotes: 'success',
                toggleSwitch3: false,
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    MessageForSalesRepNotes: '',
                    MessageForSalesRepTypeNotes: null,
                  });
                  this.componentDidMount();
                }, 3000);
              },
            );
          }
          utilityObj.loaderView('hide');
        } else {
          if (note_for === 'INTERNAL') {
            this.setState(
              {
                MessageNotes: res.data.message,
                MessageTypeNotes: 'danger',
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    MessageNotes: '',
                    MessageTypeNotes: null,
                  });
                }, 3000);
              },
            );
          } else if (note_for === 'ANALYTICAL') {
            this.setState(
              {
                MessageAnalyticalNotes: res.data.message,
                MessageAnalyticalTypeNotes: 'danger',
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    MessageAnalyticalNotes: '',
                    MessageAnalyticalTypeNotes: null,
                  });
                }, 3000);
              },
            );
          } else if (note_for === 'SALESREP') {
            this.setState(
              {
                MessageForSalesRepNotes: res.data.message,
                MessageForSalesRepTypeNotes: 'danger',
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    MessageForSalesRepNotes: '',
                    MessageForSalesRepTypeNotes: null,
                  });
                }, 3000);
              },
            );
          }

          utilityObj.loaderView('hide');
        }
      })
      .catch((error) => {
        console.log(error);
        utilityObj.loaderView('hide');
      });
  };

  getSessionEventDetail = async () => {
    const { event_sesion_id } = this.state;

    var user_role = localStorage.getItem('adminsrToken');
    var session_detail_url = 'get-session-event-detail';
    if (user_role == 4) {
      session_detail_url = 'sales-rep/get-session-event-detail';
    } else {
    }
    let payload = {
      session_event_id: event_sesion_id,
    };

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    };
    await axios
      .post(session_detail_url, payload, {
        headers,
      })
      .then((res) => {
        console.log(
          `Response from ${session_detail_url}: ${JSON.stringify(res)}`,
        );
        if (res.data.code === 200) {
          this.setState(
            {
              event_session_detail: res.data.data[0],
              SetOppoDetails: res.data.data[0],
              eventData: res.data.data[0],
              loading: false,
            },
            () => {
              this.getSalesRepSurvey();
              this.getSalesManagerNotes();
              this.getSalesManagerNotesForRep();
            },
          );
          utilityObj.loaderView('hide');
        } else {
          this.setState(
            {
              MessageNotes: res.data.message,
              MessageTypeNotes: 'danger',
              loading: false,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  MessageNotes: '',
                  MessageTypeNotes: null,
                });
              }, 3000);
            },
          );
          utilityObj.loaderView('hide');
        }
      })
      .catch((error) => {
        console.log(error);
        utilityObj.loaderView('hide');
      });
    this.getActionItems(event_sesion_id);
    this.getAISummary(event_sesion_id);
  };
  getActionItems = async (event_sesion_id) => {
    // console.log('getActionItems called with event_sesion_id', event_sesion_id);
    const response = await axios.post('/get-action-items', {
      session_id: event_sesion_id, //97,
    });
    const actionItems = response.data; //sampleActionItems;
    console.log('actionItems', actionItems);
    if (actionItems.length > 0) {
      actionItems.map((item) => {
        item.actionItemState = 'toggle';
        item.toggle_state = 'off'; // [ ] set toggle_state by querying task_status
        if (typeof item.transcript === 'string') {
          item.transcript = [{ transcript: item.transcript }];
        }
      });
      // console.log('actionItems', actionItems);
      this.setState({ actionItems });
      this.setState({ hasActionItems: true });
    }
  };
  getAISummary = async (event_sesion_id) => {
    const response = await axios.post('/get-transcript-summary', {
      session_id: event_sesion_id, //68
    });
    const AISummary = response.data;
    this.setState({ AISummary });
  };
  getSessionEventInternalNotes = async () => {
    const { event_sesion_id } = this.state;

    var note_by_userid = localStorage.getItem('userid');
    let payload = {
      session_id: event_sesion_id,
      note_for: 'INTERNAL',
      user_id: note_by_userid,
    };

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    };
    await axios
      .post(`coaching/get-coaching-session-notes`, payload, {
        headers,
      })
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          this.setState(
            {
              technical_notes: res.data.data,
            },
            () => {},
          );
        } else {
          this.setState(
            {
              MessageNotes: res.data.message,
              MessageTypeNotes: 'danger',
            },
            () => {
              setTimeout(() => {
                this.setState({
                  MessageNotes: '',
                  MessageTypeNotes: null,
                });
              }, 3000);
            },
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  setTaskReportShow = (newValue) => {
    this.setState({ editTaskReportShow: newValue });
  };

  editTaskReport = () => {
    return (
      <>
        <form>
          <div className="form-group">
            <label className="mb-0">Task Name</label>
            <div className="feild-text py-3 f-600 f2015">
              Developed business risk impact statement.
            </div>
          </div>
          <div className="form-group">
            <label className="mb-0">Task Status</label>
            <div className="feild-text py-3 f-600 f2015 color_green">
              Completed
            </div>
            <div className="feild-text py-3 f-600 f2015 color_red">
              Completed Rejected
            </div>
            <div className="feild-text py-3 f-600 f2015 color_yellow">
              Awaiting Review
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="mb-0">Assigned Date</label>
                <div className="feild-text py-3 f-600 f2015">06-09-2021</div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="mb-0">Completed Date</label>
                <div className="feild-text py-3 f-600 f2015">06-15-2021</div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="mb-0">Notes by Vania Rice</label>
            <div className="feild-text py-1 f-500 f2015">
              Lorem ipsum dolor sit amet, consectetur ading elit, sed do eiusmod
              tempor incididunt ut lorem ipsum dolor sit amet, consectetur
            </div>
          </div>
          <div className="form-group">
            <label className="mb-0">Notes by George Swanson</label>
            <div className="feild-text py-1 f-500 f2015">
              Lorem ipsum dolor sit amet, consectetur ading elit, sed do eiusmod
              tempor incididunt ut lorem ipsum dolor sit amet, consectetur
            </div>
          </div>
          <div className="form-group">
            <label className="mb-0 color_red">Rejection Notes</label>
            <textarea
              className="form-control rejection-note f-600 f2015"
              placeholder="Notes"
            ></textarea>
          </div>
          <div className="modal-footer justify-content-center">
            <button
              type="button"
              className="btn dark-btn mx-2"
              onClick={() => this.setTaskReportShow(false)}
            >
              Save
            </button>
            <button
              type="button"
              className="btn light-btn  cancel-btn mx-2"
              onClick={() => this.setTaskReportShow(false)}
            >
              Cancel
            </button>
          </div>
        </form>
      </>
    );
  };

  setTaskReportShow2 = (newValue) => {
    this.setState({ editTaskReportShow2: newValue });
  };

  editTaskReport2 = () => {
    return (
      <>
        <form>
          <div className="form-group">
            <label className="mb-0">Task Name</label>
            <div className="feild-text py-3 f-600 f2015">
              Developed business risk impact statement.
            </div>
          </div>
          <div className="form-group">
            <label className="mb-0">Task Status</label>
            <div className="feild-text py-3 f-600 f2015 color_green">
              Completed
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="mb-0">Assigned Date</label>
                <div className="feild-text py-3 f-600 f2015">06-09-2021</div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="mb-0">Completed Date</label>
                <div className="feild-text py-3 f-600 f2015">06-15-2021</div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="mb-0">Notes by Vania Rice</label>
            <div className="feild-text py-1 f-500 f2015">
              Lorem ipsum dolor sit amet, consectetur ading elit, sed do eiusmod
              tempor incididunt ut lorem ipsum dolor sit amet, consectetur
            </div>
          </div>
          <div className="form-group">
            <label className="mb-0">Notes by George Swanson</label>
            <div className="feild-text py-1 f-500 f2015">
              Lorem ipsum dolor sit amet, consectetur ading elit, sed do eiusmod
              tempor incididunt ut lorem ipsum dolor sit amet, consectetur
            </div>
          </div>
        </form>
      </>
    );
  };

  setTaskReportShow3 = (newValue) => {
    this.setState({ editTaskReportShow3: newValue });
  };

  editTaskReport3 = () => {
    return (
      <>
        <form>
          <div className="form-group">
            <label className="mb-0">Task Name</label>
            <div className="feild-text py-3 f-600 f2015">
              Developed business risk impact statement.
            </div>
          </div>
          <div className="form-group">
            <label className="mb-0">Task Status</label>
            <div className="feild-text py-3 f-600 f2015 color_red">
              Rejected
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="mb-0">Assigned Date</label>
                <div className="feild-text py-3 f-600 f2015">06-09-2021</div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="mb-0">Completed Date</label>
                <div className="feild-text py-3 f-600 f2015">06-15-2021</div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="mb-0">Notes by Vania Rice</label>
            <div className="feild-text py-1 f-500 f2015">
              Lorem ipsum dolor sit amet, consectetur ading elit, sed do eiusmod
              tempor incididunt ut lorem ipsum dolor sit amet, consectetur
            </div>
          </div>

          <div className="form-group">
            <label className="mb-0 color_red">Rejection Notes</label>
            <div className="feild-text py-1 f-500 f2015">
              Lorem ipsum dolor sit amet, consectetur ading elit, sed do eiusmod
              tempor incididunt ut lorem ipsum dolor sit amet, consectetur
            </div>
          </div>

          <div className="form-head my-3 f2015  clearfix">
            <span className="bg-white d-inline-block mb-2  f-600 pr-3">
              Notes
            </span>{' '}
            <small
              className="float-right bg-white d-inline-block pl-3 link"
              onClick={() => this.appendNotes()}
            >
              {' '}
              + Add
            </small>
          </div>
          <div className="form-group">
            <label className="mb-0 w-100">Notes by George Swanson </label>
            <textarea
              className="form-control f-500 f2015"
              placeholder="Type a Note"
            ></textarea>
          </div>
          {this.state.notes.map((input) => (
            <>
              <div className="form-group">
                <label className="mb-0 w-100">
                  Notes by George Swanson{' '}
                  <span
                    className="float-right bg-white d-inline-block pl-3 link color_red"
                    onClick={() => this.removeNotes(input)}
                  >
                    {' '}
                    - Clear
                  </span>
                </label>
                <textarea
                  className="form-control f-500 f2015"
                  placeholder="Type a Note"
                ></textarea>
              </div>
            </>
          ))}
          <div className="modal-footer justify-content-center">
            <button
              type="button"
              className="btn dark-btn mx-2"
              onClick={() => this.setTaskReportShow3(false)}
            >
              Save
            </button>
            <button
              type="button"
              className="btn light-btn cancel-btn mx-2"
              onClick={() => this.setTaskReportShow3(false)}
            >
              Cancel
            </button>
          </div>
        </form>
      </>
    );
  };

  setTaskReportShow4 = (newValue) => {
    this.setState({ editTaskReportShow4: newValue });
  };

  editTaskReport4 = () => {
    return (
      <>
        <form>
          <div className="form-group">
            <label className="mb-0">Task Name</label>
            <div className="feild-text py-3 f-600 f2015">
              Developed business risk impact statement.
            </div>
          </div>
          <div className="form-group">
            <label className="mb-0">Task Status</label>
            <div className="feild-text py-3 f-600 f2015 color_yellow">
              Awaiting Review
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="mb-0">Assigned Date</label>
                <div className="feild-text py-3 f-600 f2015">06-09-2021</div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="mb-0">Completed Date</label>
                <div className="feild-text py-3 f-600 f2015">06-15-2021</div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="mb-0">Notes by Vania Rice</label>
            <div className="feild-text py-1 f-500 f2015">
              Lorem ipsum dolor sit amet, consectetur ading elit, sed do eiusmod
              tempor incididunt ut lorem ipsum dolor sit amet, consectetur
            </div>
          </div>
          <div className="form-group">
            <label className="mb-0">Notes by George Swanson</label>
            <div className="feild-text py-1 f-500 f2015">
              Lorem ipsum dolor sit amet, consectetur ading elit, sed do eiusmod
              tempor incididunt ut lorem ipsum dolor sit amet, consectetur
            </div>
          </div>
          {this.state.showRejectionNote === true ? (
            <>
              <div className="form-group">
                <label className="mb-0 color_red">Rejection Notes</label>
                <textarea
                  className="form-control rejection-note f-600 f2015"
                  placeholder="Notes"
                ></textarea>
              </div>
              <div className="modal-footer justify-content-center">
                <button
                  type="button"
                  className="btn dark-btn mx-2"
                  onClick={() => this.setTaskReportShow4(false)}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn light-btn  cancel-btn mx-2"
                  onClick={() => this.setshowRejectionNote(false)}
                >
                  Cancel
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="modal-footer justify-content-center">
                <button
                  type="button"
                  className="btn dark-btn mx-2"
                  onClick={() => this.setTaskReportShow4(false)}
                >
                  Approve
                </button>
                <button
                  type="button"
                  className="btn light-btn  cancel-btn mx-2"
                  onClick={() => this.setshowRejectionNote(true)}
                >
                  Reject
                </button>
              </div>
            </>
          )}
        </form>
      </>
    );
  };

  setshowRejectionNote = (newValue) => {
    this.setState({ showRejectionNote: newValue });
  };

  setTaskReportShow5 = (newValue) => {
    this.setState({ editTaskReportShow5: newValue });
  };

  editTaskReport5 = () => {
    return (
      <>
        <div className="my-3">
          <p className="m-0 text-center">
            Completing the task will submit the task for review to the Sales
            Manager.{' '}
          </p>
          <p className="m-0 mt-3 text-center">
            Are you sure you want to complete this task?{' '}
          </p>
          <p></p>
          <div className="modal-footer border-0 justify-content-center">
            <button
              type="button"
              className="btn dark-btn mx-2"
              onClick={() => this.setTaskReportShow5(true)}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn light-btn mx-2"
              onClick={() => this.setTaskReportShow5(false)}
            >
              No
            </button>
          </div>
        </div>
      </>
    );
  };

  setTaskReportShow6 = (newValue) => {
    this.setState({ editTaskReportShow6: newValue });
  };

  editTaskReport6 = () => {
    return (
      <>
        <form>
          <div className="form-group">
            <label className="mb-0">Task Name</label>
            <div className="feild-text py-3 f-600 f2015">
              Developed business risk impact statement.
            </div>
          </div>
          <div className="form-group">
            <label className="mb-0">Task Status</label>
            <div className="feild-text py-3 f-600 f2015 color_blue">
              Assigned
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="mb-0">Assigned Date</label>
                <div className="feild-text py-3 f-600 f2015">06-09-2021</div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="mb-0">Completed Date</label>
                <div className="feild-text py-3 f-600 f2015">-</div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="mb-0">Notes by Vania Rice</label>
            <div className="feild-text py-1 f-500 f2015">
              Lorem ipsum dolor sit amet, consectetur ading elit, sed do eiusmod
              tempor incididunt ut lorem ipsum dolor sit amet, consectetur
            </div>
          </div>
          <div className="form-head my-3 f2015  clearfix">
            <span className="bg-white d-inline-block mb-2  f-600 pr-3">
              Notes
            </span>{' '}
            <small
              className="float-right bg-white d-inline-block pl-3 link"
              onClick={() => this.appendNotes()}
            >
              {' '}
              + Add
            </small>
          </div>
          <div className="form-group">
            <label className="mb-0  w-100">Notes by George Swanson </label>
            <textarea
              className="form-control f-500 f2015"
              placeholder="Type a Note"
            ></textarea>
          </div>
          {this.state.notes.map((input) => (
            <>
              <div className="form-group">
                <label className="mb-0  w-100">
                  Notes by George Swanson{' '}
                  <span
                    className="float-right bg-white d-inline-block pl-3 link color_red"
                    onClick={() => this.removeNotes(input)}
                  >
                    {' '}
                    - Clear
                  </span>
                </label>
                <textarea
                  className="form-control f-500 f2015"
                  placeholder="Type a Note"
                ></textarea>
              </div>
            </>
          ))}

          <div className="modal-footer justify-content-center">
            <button
              type="button"
              className="btn dark-btn mx-2"
              onClick={() => this.setTaskReportShow6(false)}
            >
              Save
            </button>
            <button
              type="button"
              className="btn light-btn cancel-btn mx-2"
              onClick={() => this.setTaskReportShow6(false)}
            >
              Cancel
            </button>
          </div>
        </form>
      </>
    );
  };

  setTaskReportShow5 = (newValue) => {
    this.setState({ editTaskReportShow5: newValue });
  };

  editTaskReport5 = () => {
    return (
      <>
        <div className="my-3">
          <p className="m-0 text-center">
            Completing the task will submit the task for review to the Sales
            Manager.{' '}
          </p>
          <p className="m-0 mt-3 text-center">
            Are you sure you want to complete this task?{' '}
          </p>
          <p></p>
          <div className="modal-footer border-0 justify-content-center">
            <button
              type="button"
              className="btn dark-btn mx-2"
              onClick={() => this.setTaskReportShow5(true)}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn light-btn mx-2"
              onClick={() => this.setTaskReportShow5(false)}
            >
              No
            </button>
          </div>
        </div>
      </>
    );
  };

  setTaskReportShow7 = (newValue) => {
    this.setState({ editTaskReportShow7: newValue });
  };

  editTaskReport7 = () => {
    return (
      <>
        <div className="my-3">
          <p className="m-0 text-center">
            Task cannot be completed. Please add notes to the task for the Sales
            Manager to review.
          </p>
          <p></p>
          <div className="modal-footer border-0 justify-content-center">
            <button
              type="button"
              className="btn light-btn mx-2"
              onClick={() => this.setTaskReportShow7(false)}
            >
              Ok
            </button>
          </div>
        </div>
      </>
    );
  };

  appendNotes() {
    var newNote = `note-${this.state.notes.length}`;
    this.setState((prevState) => ({
      notes: prevState.notes.concat([newNote]),
    }));
  }
  removeNotes = (deleteval) => {
    this.setState({
      notes: this.state.notes.filter(function (notes) {
        return notes !== deleteval;
      }),
    });
  };

  toggleButton = () => {
    this.setState({ toggleSwitch: !this.state.toggleSwitch });
  };
  toggleButton2 = () => {
    this.setState({ toggleSwitch2: !this.state.toggleSwitch2 }, () => {
      if (this.state.toggleSwitch2 === false) {
        this.getSalesManagerNotes();
      }
    });
  };
  toggleButton3 = () => {
    this.setState({ toggleSwitch3: !this.state.toggleSwitch3 }, () => {
      this.componentDidMount();
    });
  };

  setMarkReviewed = (newValue) => {
    const { eventData } = this.state;
    if (newValue === true) {
      this.setState({ markReviewedID: eventData.id }, () => {
        this.setState({
          MarkReviewedshow: newValue,
        });
      });
    } else {
      this.setState({
        MarkReviewedshow: newValue,
      });
    }
  };

  markReviewedBySalesManager = async () => {
    const { markReviewedID } = this.state;
    utilityObj.loaderView('show');
    const payload = {
      session_id: markReviewedID,
    };
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    };

    await axios
      .post(`coaching/mark-review-complete`, payload, {
        headers,
      })
      .then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          this.setState(
            {
              MessageReviewed: 'Mark reviewed successfully.',
              MessageTypeReviewed: 'success',
            },
            () => {
              setTimeout(() => {
                this.setState({
                  MessageReviewed: '',
                  MessageTypeReviewed: null,
                  toggleSwitch2: false,
                });
                this.setMarkReviewed(false);
                this.componentDidMount();
              }, 3000);
            },
          );
          utilityObj.loaderView('hide');
        } else {
          this.setState(
            {
              MessageReviewed: res.data.message,
              MessageTypeReviewed: 'danger',
            },
            () => {
              setTimeout(() => {
                this.setState({
                  MessageReviewed: '',
                  MessageTypeReviewed: null,
                });
              }, 3000);
            },
          );
          utilityObj.loaderView('hide');
        }
      })
      .catch((error) => {
        console.log(error);
        utilityObj.loaderView('hide');
      });
  };

  MarkReviwedModal = () => {
    const { eventData, MessageReviewed, MessageTypeReviewed } = this.state;
    return (
      <>
        <form>
          <div className="text-center mx-2 my-4">
            {MessageReviewed && MessageReviewed !== null ? (
              <div className={`alert alert-${MessageTypeReviewed}`}>
                {MessageReviewed}
              </div>
            ) : (
              ``
            )}

            <p className="m-0">
              {' '}
              Marking the analytics as reviewed, will change the coaching event
              status to Awaiting Completion. Do you want to continue?
            </p>

            <div className="modal-footer border-0 mb-4 justify-content-center">
              <button
                type="button"
                className="btn dark-btn mx-2"
                onClick={() => this.markReviewedBySalesManager()}
              >
                Continue
              </button>
              <button
                type="button"
                className="btn light-btn mx-2"
                onClick={() => this.setMarkReviewed(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </>
    );
  };

  render() {
    const {
      loading,
      rep_session_survey,
      rep_session_survey_status,
      session_survey,
      session_survey_status,
      user_role,
      user_id,
      ButtonHideB,
      ButtonHideC,
      technical_notes,
      event_session_detail,
      SetOppoDetails,
      toggleSwitch2,
      toggleSwitch3,
      noteforsalesrepbody,
      forsalesrepNoted,
      selectedButton,
      MessageForSalesRepNotes,
      MessageForSalesRepTypeNotes,
      MarkReviewedshow,
      analyticalnotebody,
      MessageAnalyticalNotes,
      MessageAnalyticalTypeNotes,
      editTaskReportShow,
      editTaskReportShow2,
      editTaskReportShow3,
      editTaskReportShow4,
      editTaskReportShow5,
      editTaskReportShow6,
      editTaskReportShow7,
      addNotesShow,
      hasActionItems,
      actionItems,
      AISummary,
    } = this.state;
    var profileImgRep =
      process.env.REACT_APP_STATIC_FILES_PATH +
      '/' +
      (event_session_detail.sales_rep_profile_pic_url !== '' &&
      event_session_detail.sales_rep_profile_pic_url !== null
        ? event_session_detail.sales_rep_profile_pic_url
        : 'default-avatar.png');
    var sales_manager_survey_accordian = '';
    if (session_survey_status == 'SUBMIT') {
      sales_manager_survey_accordian = 'collapseSurvey1';
    }

    var sales_rep_survey_accordian = '';
    if (rep_session_survey_status == 'SUBMIT') {
      sales_rep_survey_accordian = 'collapserRepSurvey';
    }
    return (
      <>
        <Modal
          title=""
          contentClasses="py-3 py-md-5"
          size="lg"
          onClose={() => this.setMarkReviewed(false)}
          show={MarkReviewedshow}
        >
          {this.MarkReviwedModal()}
        </Modal>
        <Modal
          title="Task Report"
          contentClasses=""
          size="md"
          onClose={() => this.setTaskReportShow(false)}
          show={editTaskReportShow}
        >
          {this.editTaskReport()}
        </Modal>
        <Modal
          title="Task Report"
          contentClasses=""
          size="md"
          onClose={() => this.setTaskReportShow2(false)}
          show={editTaskReportShow2}
        >
          {this.editTaskReport2()}
        </Modal>
        <Modal
          title="Task Report"
          contentClasses=""
          size="md"
          onClose={() => this.setTaskReportShow3(false)}
          show={editTaskReportShow3}
        >
          {this.editTaskReport3()}
        </Modal>
        <Modal
          title="Task Report"
          contentClasses=""
          size="md"
          onClose={() => this.setTaskReportShow4(false)}
          show={editTaskReportShow4}
        >
          {this.editTaskReport4()}
        </Modal>

        <Modal
          title=""
          contentClasses=""
          size="md"
          onClose={() => this.setTaskReportShow5(false)}
          show={editTaskReportShow5}
        >
          {this.editTaskReport5()}
        </Modal>
        <Modal
          title="Task Report"
          contentClasses=""
          size="md"
          onClose={() => this.setTaskReportShow6(false)}
          show={editTaskReportShow6}
        >
          {this.editTaskReport6()}
        </Modal>

        <Modal
          title=""
          contentClasses=""
          size="md"
          onClose={() => this.setTaskReportShow7(false)}
          show={editTaskReportShow7}
        >
          {this.editTaskReport7()}
        </Modal>

        <Modal
          title={
            this.state.notesType === 'text' ? 'Add Text Note' : 'Add Voice Note'
          }
          contentClasses=""
          size="md"
          onClose={() => this.setAddNotes(false, 'text')}
          show={addNotesShow}
        >
          {this.addNotes()}
        </Modal>
        {loading === false ? (
          <>
            <main className="main-contant opportunity-management-main">
              <div className="contents">
                <div className="mb-3 mb-lg-4 text-center d-sm-flex align-items-center justify-content-center">
                  <h3 className="title-head  mb-0 text-center title-head  mb-0 text-center pl-sm-5 ml-sm-5 pl-4">
                    {event_session_detail.event_title}
                  </h3>
                  <span
                    className={`status-tag border-0 ${event_session_detail.caoching_session_status_color}  d-inline-block text-white ml-sm-3 my-2 my-sm-0`}
                  >
                    {event_session_detail.caoching_session_status}
                  </span>
                </div>
                <Link
                  className="mt-0 mb-3 d-block  go-back d-flex align-items-center"
                  to={'coaching'}
                >
                  <i className="iconmoon icon-chevron-left"></i>{' '}
                  <span className="d-none d-sm-inline-block"> Go Back </span>{' '}
                </Link>
                <div className="row row-mx-10">
                  <div className="event-details col-lg-12">
                    <div className="cardWrap mb-3">
                      <div className="form-head mb-3 f2015 clearfix">
                        <span className="bg-white d-inline-block mb-2  f-600 pr-3">
                          Event Details
                        </span>
                      </div>
                      <div className="form-group">
                        <div className="d-flex flex-wrap">
                          <div className="left__content flex-grow-1 d-flex flex-column">
                            <div className="bg_light_gray rounded flex-grow-1 p-3">
                              <div className="shedule-details f-16 mb-0 f-500">
                                <div className="pb-1 mb-2">
                                  <div className="main-profile d-flex flex-column align-items-center">
                                    <img
                                      className="profile-img-wrap"
                                      src={profileImgRep}
                                      alt={event_session_detail.sales_rep_name}
                                      title={
                                        event_session_detail.sales_rep_name
                                      }
                                    />
                                    <div className="my-2">
                                      <div className="name f-600 mb-1">
                                        {event_session_detail.sales_rep_name}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="pb-1 mb-2 d-flex justify-content-around">
                                  <span className="meeting-date">
                                    <i className="ri-calendar-event-line color_blue mr-2"></i>{' '}
                                    {moment(
                                      parseInt(
                                        event_session_detail.start_date_tmestamp,
                                      ) * 1000,
                                    )
                                      .utc()
                                      .format('dddd, MMMM DD yyyy')}
                                  </span>
                                  <span className="meeting-time">
                                    <i className="ri-time-line color_blue mr-2"></i>{' '}
                                    {moment(
                                      parseInt(
                                        event_session_detail.start_date_tmestamp,
                                      ) * 1000,
                                    )
                                      .utc()
                                      .format('hh:mma')}{' '}
                                    -{' '}
                                    {moment(
                                      parseInt(
                                        event_session_detail.end_date_tmestamp,
                                      ) * 1000,
                                    )
                                      .utc()
                                      .format('hh:mma')}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="right__content d-flex flex-wrap">
                            <div className="p-3">
                              <div className="form-group">
                                <label className="mb-0">Opportunity</label>
                                <div className="feild-text py-3 f-600 f2015">
                                  {event_session_detail.title}
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="mb-0">Stage</label>
                                <div className="feild-text py-3  f2015">
                                  {event_session_detail.stage}
                                </div>
                              </div>
                            </div>
                            <div className="p-3">
                              <div className="form-group">
                                <label className="mb-0">Task</label>
                                <div className="feild-text py-3  f2015">
                                  {event_session_detail.task}
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="mb-0">Description</label>
                                <div className="feild-text py-3 word-break-all f2015">
                                  {event_session_detail.description}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="cardWrap mb-3">
                      {SetOppoDetails.zoom_meeting_s3_url != '' ? (
                        <video
                          width="100%"
                          height="auto"
                          controls
                          src={SetOppoDetails.zoom_meeting_s3_url}
                          controlsList="nodownload"
                        ></video>
                      ) : (
                        <div className="empty-video text-center my-5 py-5">
                          <i className="ri-video-line ri-3x f-100 d-block"></i>
                          Video processing in progress.
                        </div>
                      )}
                    </div>
                    {user_role == 3 ? (
                      <div className="cardWrap mb-3">
                        <div className="form-head mb-3 f2015  clearfix">
                          <span className="bg-white d-inline-block f-600 pr-3">
                            Manager's Notes
                          </span>
                          {SetOppoDetails.session_status !==
                          'completed_sessions' ? (
                            <>
                              <small
                                className="float-right bg-white d-inline-block pl-3 f-14 link"
                                onClick={() => this.setAddNotes(true, 'text')}
                              >
                                {' '}
                                <i className="ri-list-unordered"></i> Text{' '}
                              </small>
                              <small
                                className="float-right bg-white d-inline-block pl-3 f-14 link"
                                onClick={() => this.setAddNotes(true, 'voice')}
                              >
                                {' '}
                                <i className="ri-mic-2-line"></i> Voice{' '}
                              </small>
                            </>
                          ) : null}
                        </div>
                        <div className="height_200">
                          <form action="">
                            {(event_session_detail.caoching_session_status ==
                              'Awaiting Completion' ||
                              event_session_detail.caoching_session_status ==
                                'Completed') &&
                            analyticalnotebody != '' ? (
                              <div className="bg_light_gray rounded px-3 py-2 pb-3 height_95  mb-3">
                                <div className="feild-text py-3 word-break-all f2015_2 color_dark  ">
                                  <b>Analytics Notes:</b> {analyticalnotebody}
                                </div>
                              </div>
                            ) : null}

                            <span></span>
                            {technical_notes.map((item) => {
                              return item.note_content_type == '0' ? (
                                <>
                                  {' '}
                                  <div className="form-group">
                                    <div className="bg_light_gray rounded px-3 py-2 pb-3 ">
                                      <div className="feild-text py-3 word-break-all f2015_2 color_dark ">
                                        {item.note_body}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {' '}
                                  <audio
                                    ref="audio_tag"
                                    style={{ width: '100%' }}
                                    src={item.note_body}
                                    controls
                                    controlsList="nodownload"
                                  />
                                </>
                              );
                            })}
                          </form>
                        </div>
                      </div>
                    ) : null}
                    {SetOppoDetails.session_status !== 'upcoming_session' &&
                    user_role == 3 ? (
                      <div className="cardWrap mb-3">
                        <div className="form-head mb-3 f2015  clearfix">
                          <span className="bg-white d-inline-block mb-2  f-600 pr-3">
                            Sales Rep's Notes
                          </span>
                          <small className="float-right bg-white mb-2 d-inline-block pl-3 f-14 link">
                            {SetOppoDetails.session_status !==
                            'completed_sessions' ? (
                              toggleSwitch3 === true &&
                              ButtonHideC === false ? (
                                <>
                                  <button
                                    type="button"
                                    onClick={() => this.saveNote(3)}
                                    className="btn dark-btn btn-small f-400 p-0 "
                                  >
                                    Save
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => this.toggleButton3()}
                                    className="btn bg-transparent f-400 py-0 px-3 "
                                  >
                                    Cancel
                                  </button>
                                </>
                              ) : (
                                <>
                                  <small onClick={() => this.toggleButton3()}>
                                    <i className="ri-edit-box-line"></i> Edit
                                  </small>
                                </>
                              )
                            ) : null}
                          </small>
                        </div>
                        <form action="">
                          {MessageForSalesRepNotes &&
                          MessageForSalesRepNotes !== null ? (
                            <div
                              className={`alert alert-${MessageForSalesRepTypeNotes}`}
                            >
                              {MessageForSalesRepNotes}
                            </div>
                          ) : (
                            ``
                          )}
                          <div className="form-group">
                            {SetOppoDetails.session_status !==
                              'completed_sessions' && toggleSwitch3 === true ? (
                              <textarea
                                className="form-control bg_light_gray border-0 rounded p-3 color_dark"
                                rows={2}
                                maxRows={2}
                                id={`noteforsalesrepbody`}
                                defaultValue={noteforsalesrepbody}
                                onChange={(e) => this.changeFunction(e)}
                                placeholder="These notes will be made visible to the Sales Rep when the coaching event is marked as Completed."
                              ></textarea>
                            ) : (
                              <div>
                                {noteforsalesrepbody == null ? (
                                  <textarea
                                    className="form-control bg_light_gray border-0 rounded p-3 color_dark"
                                    rows={2}
                                    maxRows={2}
                                    id={`noteforsalesrepbody`}
                                    disabled
                                    value={noteforsalesrepbody}
                                    onChange={(e) => this.changeFunction(e)}
                                    placeholder="These notes will be made visible to the Sales Rep when the coaching event is marked as Completed."
                                  ></textarea>
                                ) : (
                                  <div>
                                    <textarea
                                      className="form-control bg_light_gray border-0 rounded p-3 color_dark"
                                      rows={2}
                                      maxRows={2}
                                      id={`noteforsalesrepbody`}
                                      disabled
                                      value={noteforsalesrepbody}
                                      placeholder="These notes will be made visible to the Sales Rep when the coaching event is marked as Completed."
                                    ></textarea>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </form>
                      </div>
                    ) : null}

                    <div className="cardWrap mb-3">
                      {user_role == 3 ? (
                        <div className="accordion" id="accordionSurvey">
                          <div
                            className="d-flex collapse-head  color_blue justify-content-between align-items-center"
                            data-toggle="collapse"
                            data-target="#collapseSurvey1"
                            aria-expanded="true"
                            aria-controls="collapseSurvey"
                          >
                            Sales Manager Survey{' '}
                            {sales_manager_survey_accordian != '' ? (
                              <i className="ri-arrow-down-s-line ri-2x f-100"></i>
                            ) : (
                              ``
                            )}
                          </div>
                          <div
                            id={sales_manager_survey_accordian}
                            className="collapse show px-2"
                            aria-labelledby="headingOne"
                            data-parent="#accordionSurvey"
                          >
                            {session_survey_status &&
                            session_survey_status == 'SUBMIT' ? (
                              <>
                                <form className="manager-survey-wrap">
                                  {session_survey && session_survey.length > 0
                                    ? session_survey.map(
                                        (survey_data, survey_index) => {
                                          var ratings = [1, 2, 3, 4, 5];

                                          return survey_data.type ==
                                            'RATING' ? (
                                            <div className="form-group px-2">
                                              <div className="feild-text py-3 f-600 f2015">
                                                {' '}
                                                {survey_data.question}
                                              </div>
                                              <div className="ratings-wrap">
                                                <ul className="list-unstyled mt-3 d-flex m-0">
                                                  {ratings.map(
                                                    (rating_value, index) => {
                                                      var checked = '';
                                                      if (
                                                        rating_value ==
                                                        survey_data.ans
                                                      ) {
                                                        checked = 'checked';
                                                      }
                                                      return (
                                                        <li className=" mr-2">
                                                          <label className="customRadio text-check">
                                                            <input
                                                              type="radio"
                                                              disabled
                                                              name={
                                                                survey_data.que_id
                                                              }
                                                              checked={checked}
                                                              value={
                                                                rating_value
                                                              }
                                                              onChange={(e) =>
                                                                this.handleChange(
                                                                  e,
                                                                  survey_index,
                                                                )
                                                              }
                                                            />
                                                            <span className="checkmark">
                                                              {rating_value}
                                                            </span>
                                                          </label>
                                                        </li>
                                                      );
                                                    },
                                                  )}
                                                </ul>
                                                <div className="show-point mt-2 d-flex justify-content-between align-items-center">
                                                  <label className="mb-0">
                                                    Lowest
                                                  </label>
                                                  <label className="mb-0">
                                                    Highest
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="form-group">
                                              <div className="feild-text py-3 f-600 f2015">
                                                {survey_data.question}{' '}
                                              </div>
                                              <div className="f-400 f2015 color_light_gray mt-2">
                                                {survey_data.ans}
                                              </div>
                                            </div>
                                          );
                                        },
                                      )
                                    : ``}
                                </form>
                              </>
                            ) : (
                              <div className="form-group">
                                <div className="feild-text py-3 mt-4 f2015 color_light_gray">
                                  To complete the survey, use the Launch Survey
                                  context menu.
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="accordion" id="accordionRepSurvey">
                          <div
                            className="d-flex collapse-head color_blue justify-content-between align-items-center"
                            data-toggle="collapse"
                            data-target="#collapseSurvey1"
                            aria-expanded="true"
                            aria-controls="collapseSurvey"
                          >
                            Sales Rep Survey{' '}
                            {sales_manager_survey_accordian != '' ? (
                              <i className="ri-arrow-down-s-line ri-2x f-100"></i>
                            ) : (
                              ``
                            )}
                          </div>
                          <div
                            id={sales_manager_survey_accordian}
                            className="collapse show px-2"
                            aria-labelledby="headingOne"
                            data-parent="#accordionSurvey"
                          >
                            {session_survey_status &&
                            session_survey_status == 'SUBMIT' ? (
                              <>
                                <form className="manager-survey-wrap">
                                  {session_survey && session_survey.length > 0
                                    ? session_survey.map(
                                        (survey_data, survey_index) => {
                                          var ratings = [1, 2, 3, 4, 5];

                                          return survey_data.type ==
                                            'RATING' ? (
                                            <div className="form-group px-2">
                                              <div className="feild-text py-3 f-600 f2015">
                                                {' '}
                                                {survey_data.question}
                                              </div>
                                              <div className="ratings-wrap">
                                                <ul className="list-unstyled mt-3 d-flex m-0">
                                                  {ratings.map(
                                                    (rating_value, index) => {
                                                      var checked = '';
                                                      if (
                                                        rating_value ==
                                                        survey_data.ans
                                                      ) {
                                                        checked = 'checked';
                                                      }
                                                      return (
                                                        <li className=" mr-2">
                                                          <label className="customRadio text-check">
                                                            <input
                                                              type="radio"
                                                              disabled
                                                              name={
                                                                survey_data.que_id
                                                              }
                                                              checked={checked}
                                                              value={
                                                                rating_value
                                                              }
                                                              onChange={(e) =>
                                                                this.handleChange(
                                                                  e,
                                                                  survey_index,
                                                                )
                                                              }
                                                            />
                                                            <span className="checkmark">
                                                              {rating_value}
                                                            </span>
                                                          </label>
                                                        </li>
                                                      );
                                                    },
                                                  )}
                                                </ul>
                                                <div className="show-point mt-2 d-flex justify-content-between align-items-center">
                                                  <label className="mb-0">
                                                    Lowest
                                                  </label>
                                                  <label className="mb-0">
                                                    Highest
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="form-group">
                                              <div className="feild-text py-3 f-600 f2015">
                                                {survey_data.question}{' '}
                                              </div>

                                              <div className="f-400 f2015 color_light_gray mt-2">
                                                {survey_data.ans}
                                              </div>
                                            </div>
                                          );
                                        },
                                      )
                                    : ``}
                                </form>
                              </>
                            ) : (
                              <div className="form-group">
                                <div className="feild-text py-3 mt-4 f2015 color_light_gray">
                                  To complete the survey, use the Launch Survey
                                  context menu.
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    {user_role == 3 && hasActionItems && (
                      <ActionItems
                        sales_manager_id={user_id}
                        sales_rep_id={event_session_detail.resourceId}
                        sales_rep_name={event_session_detail.sales_rep_name}
                        actionItems={actionItems}
                      />
                    )}
                    {user_role == 4 ? (
                      <div className="cardWrap mb-3">
                        <div className="form-head mb-3 f2015  clearfix">
                          <span className="bg-white  d-inline-block  mb-2 f-600 pr-3">
                            Manager Notes
                          </span>
                        </div>
                        <div className="form-group">
                          <div className="bg_light_gray rounded px-3 py-2 pb-3 height_95">
                            <div className="feild-text py-3 word-break-all f2015 color_light_gray ">
                              {event_session_detail.caoching_session_status ==
                              'Completed Session'
                                ? event_session_detail.manager_note
                                : 'No notes added by Manager'}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {SetOppoDetails.session_status === 'analytical_feedback' &&
                    user_role == 3 ? (
                      <div className="cardWrap mb-3">
                        <div className="form-head mb-3 f2015  clearfix">
                          <span
                            className="tabs"
                            style={{ border: '10px solid white' }}
                          >
                            <button
                              className={`bg-white d-inline-block f-600 mb-2 px-3 ${
                                selectedButton === 'Summary'
                                  ? 'selected'
                                  : 'not-selected'
                              }`}
                              style={{}}
                              onClick={() =>
                                this.setState({ selectedButton: 'Summary' })
                              }
                            >
                              Summary
                            </button>
                            <button
                              className={`bg-white d-inline-block f-600 mb-2 px-3 pt-1 ${
                                selectedButton === 'Transcript'
                                  ? 'selected'
                                  : 'not-selected'
                              }`}
                              style={{}}
                              onClick={() =>
                                this.setState({ selectedButton: 'Transcript' })
                              }
                            >
                              Transcript
                            </button>
                          </span>
                          {selectedButton === 'Summary' && (
                            <small className="float-right bg-white d-inline-block mb-2  pl-3 f-14 link">
                              {toggleSwitch2 === true ? (
                                ButtonHideB === false ? (
                                  <>
                                    <button
                                      type="button"
                                      onClick={() => this.saveNote(2)}
                                      className="btn dark-btn btn-small f-400 p-0 "
                                    >
                                      Save
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => this.toggleButton2()}
                                      className="btn bg-transparent f-400 py-0 px-3 "
                                    >
                                      Cancel
                                    </button>
                                  </>
                                ) : null
                              ) : (
                                <>
                                  <small onClick={() => this.toggleButton2()}>
                                    <i className="ri-edit-box-line"></i> Edit
                                  </small>
                                </>
                              )}
                            </small>
                          )}
                        </div>
                        {selectedButton === 'Summary' && (
                          <form action="">
                            {false ? (
                              <div className="analytical-notes">
                                <div
                                  className={`alert alert-${MessageAnalyticalTypeNotes}`}
                                >
                                  {MessageAnalyticalNotes}
                                </div>

                                <div className="form-group">
                                  {toggleSwitch2 !== true ? (
                                    <textarea
                                      className="form-control bg_light_gray border-0 rounded p-3 color_dark"
                                      rows={2}
                                      maxRows={2}
                                      id={`analyticalnotebody`}
                                      disabled
                                      value={analyticalnotebody}
                                      onChange={(e) => this.changeFunction(e)}
                                      placeholder="Click edit to add summary notes for analytics and mark them reviewed."
                                    ></textarea>
                                  ) : (
                                    <textarea
                                      className="form-control bg_light_gray border-0 rounded p-3 color_dark"
                                      rows={2}
                                      maxRows={2}
                                      id={`analyticalnotebody`}
                                      defaultValue={analyticalnotebody}
                                      onChange={(e) => this.changeFunction(e)}
                                      placeholder="Click edit to add summary notes for analytics and mark them reviewed."
                                    ></textarea>
                                  )}
                                </div>
                                <div className="text-right">
                                  {toggleSwitch2 !== true &&
                                  analyticalnotebody !== '' ? (
                                    <button
                                      type="button"
                                      className="btn dark-btn mr-0"
                                      onClick={() => this.setMarkReviewed(true)}
                                    >
                                      Mark Reviewed
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn light-btn mr-0"
                                      disabled
                                    >
                                      Mark Reviewed
                                    </button>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="ai-summary form-control bg_light_gray border-0 rounded p-3 color_dark">
                                {AISummary.split('\n').map((line, i) => (
                                  <p key={i}>{line}</p>
                                ))}
                              </div>
                            )}
                          </form>
                        )}
                        {selectedButton === 'Transcript' && (
                          <iframe
                            width="100%"
                            height="480"
                            src={SetOppoDetails.video_indexer_iframe_src}
                            frameBorder={0}
                            allowFullScreen
                          ></iframe>
                        )}
                      </div>
                    ) : null}
                    {user_role == 3 ? (
                      <div className="cardWrap mb-3">
                        <div className="accordion" id="accordionRepSurvey">
                          <div
                            className="d-flex collapse-head  color_blue justify-content-between align-items-center"
                            data-toggle="collapse"
                            data-target="#collapserRepSurvey"
                            aria-expanded="true"
                            aria-controls="collapserRepSurvey"
                          >
                            Sales Rep Survey{' '}
                            {sales_rep_survey_accordian != '' ? (
                              <i className="ri-arrow-down-s-line ri-2x f-100"></i>
                            ) : (
                              ``
                            )}
                          </div>
                          <div
                            id={sales_rep_survey_accordian}
                            className="collapse show px-2"
                            aria-labelledby="headingOne"
                            data-parent="#accordionSurvey"
                          >
                            {rep_session_survey_status &&
                            rep_session_survey_status == 'SUBMIT' ? (
                              <>
                                <form className="manager-survey-wrap">
                                  {rep_session_survey &&
                                  rep_session_survey.length > 0
                                    ? rep_session_survey.map(
                                        (survey_data, survey_index) => {
                                          var ratings = [1, 2, 3, 4, 5];

                                          return survey_data.type ==
                                            'RATING' ? (
                                            <div className="form-group px-2">
                                              <div className="feild-text py-3 f-600 f2015">
                                                {' '}
                                                {survey_data.question}
                                              </div>
                                              <div className="ratings-wrap">
                                                <ul className="list-unstyled mt-3 d-flex m-0">
                                                  {ratings.map(
                                                    (rating_value, index) => {
                                                      var checked = '';
                                                      if (
                                                        rating_value ==
                                                        survey_data.ans
                                                      ) {
                                                        checked = 'checked';
                                                      }
                                                      return (
                                                        <li className=" mr-2">
                                                          <label className="customRadio text-check">
                                                            <input
                                                              type="radio"
                                                              disabled
                                                              name={
                                                                survey_data.que_id
                                                              }
                                                              checked={checked}
                                                              value={
                                                                rating_value
                                                              }
                                                              onChange={(e) =>
                                                                this.handleChange(
                                                                  e,
                                                                  survey_index,
                                                                )
                                                              }
                                                            />
                                                            <span className="checkmark">
                                                              {rating_value}
                                                            </span>
                                                          </label>
                                                        </li>
                                                      );
                                                    },
                                                  )}
                                                </ul>
                                                <div className="show-point mt-2 d-flex justify-content-between align-items-center">
                                                  <label className="mb-0">
                                                    Lowest
                                                  </label>
                                                  <label className="mb-0">
                                                    Highest
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="form-group">
                                              <div className="feild-text py-3 f-600 f2015">
                                                {survey_data.question}{' '}
                                              </div>
                                              {/* <textarea className="form-control f-400 f2015 " disabled style={{borderBottomColor: "#003366;"}} placeholder="" name={survey_data.que_id} onChange={(e)=>this.handleChange(e,survey_index)}>{survey_data.ans}</textarea> */}
                                              <div className="f-400 f2015 color_light_gray mt-2">
                                                {survey_data.ans}
                                              </div>
                                            </div>
                                          );
                                        },
                                      )
                                    : ``}
                                </form>
                              </>
                            ) : (
                              <div className="form-group">
                                <div className="feild-text py-3 mt-4 f2015 color_light_gray">
                                  The Sales Rep has not submitted the survey.
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </main>
          </>
        ) : null}
      </>
    );
  }
}
