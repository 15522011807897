import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

/**
 * Renders a date picker component with the ability to select a date.
 *
 * @component
 * @example
 * return (
 *   <DateWrapper />
 * )
 */
// TODO: handleDatePicked form the parent
export function DateWrapper({ onClick, dueDate, handleDatePicked }) {
  return (
    <div className="date" onClick={onClick}>
      <div className="cal" />
      <DatePicker
        className="bg-transparent border-0 placeholder"
        selected={dueDate}
        onChange={handleDatePicked}
      />
      <div className="text-wrapper-2">*</div>
    </div>
  );
}
