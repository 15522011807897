
import './App.css';
import { useEffect } from 'react';
import io from 'socket.io-client';
import { useSpeechSynthesis } from "react-speech-kit";
import Header from './components/system_admin/commons/header';
import Sidebar from './components/system_admin/commons/sidebar';
import MyProfile from './components/system_admin/commons/myProfile';

import Header_sales_rep from './components/sales_rep/commons/header';
import Sidebar_sales_rep from './components/sales_rep/commons/sidebar';
import MyProfile_sales_rep from './components/sales_rep/commons/myProfile';


import LandingOrganizationStructure from './components/system_admin/landingOrganizationStructure';
import InactiveOrganizationStructure from './components/system_admin/inactiveOrganizationStructure';
import InitiateOrganizationStructure from './components/system_admin/initiateOrganizationStructure';
import SuccessOrganizationStructure from './components/system_admin/successOrganizationStructure';
import UserManagement from './components/system_admin/userManagement';
import InitiateUserManagement from './components/system_admin/initiateUserManagement';
import SuccessUserManagement from './components/system_admin/successUserManagement';
import ConfirmUserManagement from './components/system_admin/confirmUserManagement';
import InitiateSalesProcess from './components/system_admin/initiateSalesProcess';
import SuccessSalesProcess from './components/system_admin/successSalesProcess';
import SalesProcess from './components/system_admin/salesProcess';
import InactiveSalesStages from './components/system_admin/inactiveSalesStages';
import AdminDashboard from './components/system_admin/dashboard';

import CoachingSalesManagerDetail from './components/sales_rep/coachingDetail';


import Dashboard_Sales_Rep from './components/sales_rep/dashboard';
import Opportunity_Sales_Rep from './components/sales_rep/opportunity';
import TaskDetails_Sales_Rep from './components/sales_rep/taskDetail';
import ReportsSalesRep from './components/sales_rep/reports';
import Coaching_Sales_Rep from './components/sales_rep/coaching';




import Login from './components/login';
import Logout from './components/logout';
import AuthScreen from './components/authScreen';
import CreatePassword from './components/createPassword';
import ResetPassword from './components/resetPassword';

import SalesRepCalendar from './components/sales_rep/calendar';
import CompanyAdminScheduler from './components/sales_rep/scheduler';
import SalesManagerScheduler from './components/sales_rep/scheduler';
import NylasAuth from './components/nylasAuth';
import ZoomAuth from './components/zoomAuth';

import {
      BrowserRouter,
      Switch,
      Route,
      Redirect,
} from "react-router-dom";
import AdminGuardedRoute from './components/guards/adminGuards';
import Setting from './components/system_admin/setting';

function App() {
      var role_ids = [1, 2, 3, 4];
      // console.log = console.info = console.warn = console.error = () => { };

      ////////////////////Socket/////////////////////

      const socket = io('https://api.corecoach.io'); // Replace with your server URL
      const { speak } = useSpeechSynthesis();
      useEffect(() => {
            // Listen for chat messages from the server
            socket.on('connection', (message) => {
                  console.log("heloooooooooooooooooooooo")
            });

            socket.on('notification', (data) => {
                  // debugger
                  // console.log(data, "sdfghjklsdfghjkl;sdfghjk");
                  // speak({ text: data?.speechOutput })
                  window.location.href = data?.route
                  // debugger
            })

            // Clean up socket connection on component unmount
            return () => {
                  socket.off('notification');
            };
      }, []);
      //////////////////////////////////////////////
      return (
            <div className="App">
                  <div className="body-content">
                        <BrowserRouter basename="/oauth" >

                              <Switch>
                                    <Route exact path="/callback" render={(props) => (<>
                                          <NylasAuth  {...props} />
                                    </>)}>
                                    </Route>
                                    <Route exact path="/zoom-callback" render={(props) => (<>
                                          <ZoomAuth  {...props} />
                                    </>)}>
                                    </Route>
                              </Switch>
                        </BrowserRouter>

                        <BrowserRouter basename="/" >

                              <Switch>
                                    <Route exact path="/" render={(props) => (<>
                                          <Login  {...props} />
                                    </>)}>
                                    </Route>
                                    <Route exact path="/login" render={(props) => (<>
                                          <Login  {...props} />
                                    </>)}>
                                    </Route>
                                    <AdminGuardedRoute exact path='/auth' Main={AuthScreen} auth={localStorage.getItem('sentotpToken')} sendotp={1} roleid={role_ids} />
                                    <Route exact path="/createPassword/:token/:uid/:first" render={(props) => (<>
                                          <CreatePassword  {...props} />
                                    </>)}>
                                    </Route>

                                    <Route exact path="/resetPassword" render={(props) => (<>
                                          <ResetPassword  {...props} />
                                    </>)}>
                                    </Route>

                              </Switch>
                        </BrowserRouter>

                        <BrowserRouter basename="system-admin/">
                              <Switch>
                                    <AdminGuardedRoute exact path='/myProfile' Main={MyProfile} Sidebar={Sidebar} Header={Header} auth={localStorage.getItem('adminsaToken')} roleid={role_ids} />
                                    <AdminGuardedRoute exact path='/dashboard' Main={AdminDashboard} Sidebar={Sidebar} Header={Header} auth={localStorage.getItem('adminsaToken')} roleid={role_ids} />
                                    <AdminGuardedRoute exact path='/organization-structure' Main={LandingOrganizationStructure} Sidebar={Sidebar} Header={Header} auth={localStorage.getItem('adminsaToken')} roleid={role_ids} />
                                    <AdminGuardedRoute exact path='/inactive-organization-structure' Main={InactiveOrganizationStructure} Sidebar={Sidebar} Header={Header} auth={localStorage.getItem('adminsaToken')} roleid={role_ids} />
                                    <AdminGuardedRoute exact path='/success-organization-structure' Main={SuccessOrganizationStructure} Sidebar={Sidebar} Header={Header} auth={localStorage.getItem('adminsaToken')} roleid={role_ids} />
                                    <AdminGuardedRoute exact path='/initiate-organization-structure' Main={InitiateOrganizationStructure} Sidebar={Sidebar} Header={Header} auth={localStorage.getItem('adminsaToken')} roleid={role_ids} />
                                    <AdminGuardedRoute exact path='/user-management' Main={UserManagement} Sidebar={Sidebar} Header={Header} auth={localStorage.getItem('adminsaToken')} roleid={role_ids} />
                                    <AdminGuardedRoute exact path='/initiate-user-management' Main={InitiateUserManagement} Sidebar={Sidebar} Header={Header} auth={localStorage.getItem('adminsaToken')} roleid={role_ids} />
                                    <AdminGuardedRoute exact path='/success-user-management' Main={SuccessUserManagement} Sidebar={Sidebar} Header={Header} auth={localStorage.getItem('adminsaToken')} roleid={role_ids} />
                                    <AdminGuardedRoute exact path='/confirm-user-management' Main={ConfirmUserManagement} Sidebar={Sidebar} Header={Header} auth={localStorage.getItem('adminsaToken')} roleid={role_ids} />
                                    <AdminGuardedRoute exact path='/initiate-sales-process' Main={InitiateSalesProcess} Sidebar={Sidebar} Header={Header} auth={localStorage.getItem('adminsaToken')} roleid={role_ids} />
                                    <AdminGuardedRoute exact path='/success-sales-process' Main={SuccessSalesProcess} Sidebar={Sidebar} Header={Header} auth={localStorage.getItem('adminsaToken')} roleid={role_ids} />
                                    <AdminGuardedRoute exact path='/sales-process' Main={SalesProcess} Sidebar={Sidebar} Header={Header} auth={localStorage.getItem('adminsaToken')} roleid={role_ids} />
                                    <AdminGuardedRoute exact path='/inactive-sales-stages' Main={InactiveSalesStages} Sidebar={Sidebar} Header={Header} auth={localStorage.getItem('adminsaToken')} roleid={role_ids} />
                                    <AdminGuardedRoute exact path='/setting' Main={Setting} Sidebar={Sidebar} Header={Header} auth={localStorage.getItem('adminsaToken')} roleid={role_ids} />

                                    <Route exact path='/' component={() => {
                                          window.location.href = '../login';
                                          return null;
                                    }} />
                                    <Route exact path='/logout' render={(props) => (<>
                                          <Logout  {...props} />
                                    </>)}>
                                    </Route>

                              </Switch>
                        </BrowserRouter>


                        <BrowserRouter basename="app/" >
                              <Switch>
                                    <AdminGuardedRoute exact path='/myProfile' Main={MyProfile_sales_rep} Sidebar={Sidebar_sales_rep} Header={Header_sales_rep} auth={localStorage.getItem('adminsrToken')} roleid={role_ids} />
                                    <AdminGuardedRoute exact path='/dashboard' Main={Dashboard_Sales_Rep} Sidebar={Sidebar_sales_rep} Header={Header_sales_rep} auth={localStorage.getItem('adminsrToken')} roleid={role_ids} />
                                    <AdminGuardedRoute exact path='/opportunities' Main={Opportunity_Sales_Rep} Sidebar={Sidebar_sales_rep} Header={Header_sales_rep} auth={localStorage.getItem('adminsrToken')} roleid={role_ids} />
                                    <AdminGuardedRoute exact path='/task-details/:opp_id' Main={TaskDetails_Sales_Rep} Sidebar={Sidebar_sales_rep} Header={Header_sales_rep} auth={localStorage.getItem('adminsrToken')} roleid={role_ids} />
                                    {/* <AdminGuardedRoute exact path='/calendar' Main={SalesManagerCalendar} Sidebar={Sidebar_sales_rep} Header={Header_sales_rep} auth={localStorage.getItem('adminsrToken')} roleid={role_ids} />   */}
                                    {role_ids == 2 ?
                                          <AdminGuardedRoute exact path='/scheduler' Main={CompanyAdminScheduler} Sidebar={Sidebar_sales_rep} Header={Header_sales_rep} auth={localStorage.getItem('admincaToken')} roleid={role_ids} />
                                          :
                                          <AdminGuardedRoute exact path='/scheduler' Main={SalesManagerScheduler} Sidebar={Sidebar_sales_rep} Header={Header_sales_rep} auth={localStorage.getItem('adminsrToken')} roleid={role_ids} />
                                    }
                                    <AdminGuardedRoute exact path='/calendar' Main={SalesRepCalendar} Sidebar={Sidebar_sales_rep} Header={Header_sales_rep} auth={localStorage.getItem('adminsrToken')} roleid={role_ids} />

                                    <AdminGuardedRoute exact path='/reports' Main={ReportsSalesRep} Sidebar={Sidebar_sales_rep} Header={Header_sales_rep} auth={localStorage.getItem('adminsrToken')} roleid={role_ids} />
                                    <AdminGuardedRoute exact path='/coaching' Main={Coaching_Sales_Rep} Sidebar={Sidebar_sales_rep} Header={Header_sales_rep} auth={localStorage.getItem('adminsrToken')} roleid={role_ids} />
                                    <AdminGuardedRoute exact path='/coaching-detail/:sesion_id' Main={CoachingSalesManagerDetail} Sidebar={Sidebar_sales_rep} Header={Header_sales_rep} auth={localStorage.getItem('adminsrToken')} roleid={role_ids} />


                                    <Route exact path='/' component={() => {
                                          window.location.href = '../login';
                                          return null;
                                    }} />
                                    <Route exact path='/logout' render={(props) => (<>
                                          <Logout  {...props} />
                                    </>)}>
                                    </Route>

                              </Switch>
                        </BrowserRouter>

                  </div>
            </div>
      );
}

export default App;