/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from 'prop-types';
import React from 'react';
import { useReducer, useEffect } from 'react';
import './style.css';

export const ToggleSwitch = ({ toggle, onToggle }) => {
  const [state, dispatch] = useReducer(reducer, {
    toggle: toggle || 'off',
  });

  useEffect(() => {
    // Call the onToggle function whenever state.toggle changes
    onToggle(state.toggle);
  }, [state.toggle, onToggle]);
  return (
    <div className={`toggle-switch ${state.toggle}`}>
      <div
        className={`indicator`}
        onClick={(event) => {
          event.stopPropagation();
          dispatch('click');
        }}
      />
    </div>
  );
};

function reducer(state, action) {
  if (state.toggle === 'off') {
    switch (action) {
      case 'click':
        return {
          toggle: 'on',
        };
    }
  }

  if (state.toggle === 'on') {
    switch (action) {
      case 'click':
        return {
          toggle: 'off',
        };
    }
  }

  return state;
}

ToggleSwitch.propTypes = {
  toggle: PropTypes.oneOf(['on', 'off']),
};
