import React from 'react';
import './style.css';
export const DeclineButton = ({
  openModal,
  className,
  width = '12',
  height = '12',
  hoverable = false,
}) => {
  return (
    <svg
      className={`decline-button ${className} ${
        hoverable ? 'hoverable rounded' : ''
      }`}
      height={height}
      viewBox="0 0 12 12"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      onClick={openModal}
    >
      <path
        className="path"
        d="M11.2416 7.35938L10.6791 2.85938C10.6448 2.58744 10.5125 2.33737 10.3069 2.15612C10.1013 1.97487 9.83658 1.87491 9.5625 1.875H1.5C1.30109 1.875 1.11032 1.95402 0.96967 2.09467C0.829018 2.23532 0.75 2.42609 0.75 2.625V6.75C0.75 6.94891 0.829018 7.13968 0.96967 7.28033C1.11032 7.42098 1.30109 7.5 1.5 7.5H3.51844L5.28937 11.0428C5.32055 11.1051 5.36847 11.1575 5.42775 11.1941C5.48703 11.2307 5.55533 11.25 5.625 11.25C6.12228 11.25 6.59919 11.0525 6.95083 10.7008C7.30246 10.3492 7.5 9.87228 7.5 9.375V8.625H10.125C10.2846 8.62505 10.4425 8.59113 10.588 8.52549C10.7335 8.45984 10.8634 8.36398 10.969 8.24426C11.0746 8.12455 11.1535 7.98373 11.2005 7.83116C11.2475 7.67859 11.2615 7.51777 11.2416 7.35938ZM3.375 6.75H1.5V2.625H3.375V6.75ZM10.4062 7.74797C10.3713 7.78817 10.3281 7.82034 10.2795 7.84226C10.231 7.86419 10.1783 7.87536 10.125 7.875H7.125C7.02554 7.875 6.93016 7.91451 6.85983 7.98483C6.78951 8.05516 6.75 8.15054 6.75 8.25V9.375C6.75007 9.6351 6.66002 9.88719 6.49516 10.0884C6.3303 10.2896 6.10082 10.4274 5.84578 10.4784L4.125 7.03641V2.625H9.5625C9.65386 2.62497 9.74209 2.65829 9.81062 2.71871C9.87915 2.77912 9.92327 2.86248 9.93469 2.95313L10.4972 7.45312C10.5042 7.50592 10.4997 7.55961 10.484 7.61051C10.4683 7.66141 10.4418 7.7083 10.4062 7.74797Z"
        fill={hoverable ? 'currentColor' : '#6A6A6A'}
      />
    </svg>
  );
};
