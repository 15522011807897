/**
 * ActionItem component represents an action item with various states and functionalities.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.actionItemState - The state of the action item. Can be one of: 'default', 'details', 'toggle-details', 'toggle-hover', 'hover', 'toggle'.
 * @param {string} props.task_title - The name of the task associated with the action item.
 * @param {string} props.task_id - The ID of the task associated with the action item.
 * @param {string} props.transcript - The transcript of the action item.
 * @returns {JSX.Element} The rendered ActionItem component.
 */
import PropTypes from 'prop-types';
import React from 'react';
import { useReducer, useEffect, useRef, useState } from 'react';
import { DeclineButton } from '../../../icons/DeclineButton'; // Remove 3 from the end
import { ToggleSwitch } from '../../ToggleSwitch';
import { FeedbackModal } from '../../FeedbackModal/FeedbackModal';
import { ActionItemDetailModal } from '../../ActionItemDetailModal/ActionItemDetailModal';
import { DetailNotes } from '../../DetailNotes/DetailNotes';
import { DateWrapper } from '../../DateWrapper/DateWrapper';
import './style.css';
import axios from '../../apiConnection/axios';

export const ActionItem = ({
  item,
  onItemChange,
  sales_manager_id,
  sales_rep_id,
  sales_rep_name,
}) => {
  const { task_id, task_title, actionItemState, transcript, toggle_state } =
    item;
  const [loading, setLoading] = useState(true);
  const [toggle, setToggle] = useState(toggle_state);
  const [isFeedbackModalOpen, setisFeedbackModalOpen] = useState(false);
  const [textareaInput, setTextareaInput] = useState('');
  const [dueDate, setDueDate] = useState(new Date());
  const [feedbackSubmitted, setfeedbackSubmitted] = useState(false);

  const [state, dispatch] = useReducer(reducer, {
    actionItemState: actionItemState || 'default',
    isDetailModalOpen: false,
  });
  const actionItemRef = useRef(null);
  const handleDateClicked = (e) => {
    e.stopPropagation();
    dispatch({ type: 'previous-clicked' });
  };
  const handleDatePicked = (date) => {
    setDueDate(date);
    handleItemChange({ dueDate: date });
  };
  const handleToggle = (newToggleState) => {
    if (newToggleState !== toggle) {
      setToggle(newToggleState);
      handleItemChange({ toggle_state: newToggleState });
    }
  };
  const handleTextareaInput = (input) => {
    setTextareaInput(input);
    handleItemChange({ notes: input });
  };
  const openFeedbackModal = () => {
    dispatch({ type: 'toggle-decline' });
    setisFeedbackModalOpen(true);
  };
  const onFeedbackModalClose = () => {
    setisFeedbackModalOpen(false);
    if (feedbackSubmitted) {
      dispatch({ type: 'set_toggle_disabled' });
    }
  };
  const closeDetailModal = () => {
    dispatch({ type: 'close-modal' });
  };
  const openFeedbackModalAndCloseDetailModal = () => {
    openFeedbackModal();
    closeDetailModal();
  };
  const handleItemChange = (newData) => {
    // When dueDate, textarea or assignStatus changes, handle it here
    onItemChange({ ...item, ...newData });
  };

  // This useEffect hook adds an event listener to the document that listens for a mousedown event.
  // If the mousedown event occurs outside of the actionItemRef element, it dispatches a 'click_outside' action.
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        actionItemRef.current &&
        !actionItemRef.current.contains(event.target)
      ) {
        dispatch({ type: 'click_outside' });
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  // query decline_feedback table by task_id and set task as declined if found
  useEffect(() => {
    async function checkIfDeclined() {
      try {
        const response = await axios.get(`opportunity/check-decline-task`, {
          params: { task_id },
        });
        const { taskDeclined } = response.data;
        if (taskDeclined) {
          console.log(`taskDeclined: ${taskDeclined}`);

          dispatch({ type: 'set_toggle_disabled' });
        }
      } catch (error) {
        console.log(error);
      }
    }
    checkIfDeclined();
    setLoading(false);
  }, []);
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div
      ref={actionItemRef}
      className={`action-item ${state.actionItemState}`}
      onMouseEnter={() => {
        if (state.actionItemState !== 'toggle-disabled') {
          dispatch({ type: 'mouse_enter' });
        }
      }}
      onClick={() => {
        if (state.actionItemState !== 'toggle-disabled') {
          dispatch({ type: 'click' });
        }
      }}
      onMouseLeave={() => {
        if (state.actionItemState !== 'toggle-disabled') {
          dispatch({ type: 'mouse_leave' });
        }
      }}
    >
      {[
        'default',
        'details',
        'hover',
        'toggle-details',
        'toggle-hover',
      ].includes(state.actionItemState) && (
        <>
          <div className={`task-buttons`}>
            {['details', 'toggle-details'].includes(state.actionItemState) && (
              <>
                <div className="task">{task_title}</div>
                <div className="buttons">
                  {state.actionItemState === 'toggle-details' && (
                    <ToggleSwitch toggle={toggle} onToggle={handleToggle} />
                  )}

                  {state.actionItemState === 'details' && (
                    <div className="status">
                      <div className="text-wrapper">Assign</div>
                    </div>
                  )}

                  <DeclineButton
                    className="decline-button"
                    openModal={openFeedbackModal}
                    height="25"
                    width="25"
                  />
                </div>
              </>
            )}

            {['default', 'hover', 'toggle-hover'].includes(
              state.actionItemState,
            ) && <>{task_title}</>}
          </div>
          <div className="date-notes">
            {['details', 'toggle-details'].includes(state.actionItemState) && (
              <>
                <DateWrapper
                  onClick={handleDateClicked}
                  handleDatePicked={handleDatePicked}
                  dueDate={dueDate}
                />
                <DetailNotes onTextareaInput={handleTextareaInput} />
              </>
            )}

            {state.actionItemState === 'toggle-hover' && (
              <ToggleSwitch toggle={toggle} onToggle={handleToggle} />
            )}

            {state.actionItemState === 'default' && (
              <div className="text-wrapper">Assign</div>
            )}

            {state.actionItemState === 'hover' && (
              <div className="status">
                <div className="text-wrapper">Assign</div>
              </div>
            )}

            {['hover', 'toggle-hover'].includes(state.actionItemState) && (
              <DeclineButton
                className="decline-button"
                openModal={openFeedbackModal}
                height="25"
                width="25"
              />
            )}
          </div>
        </>
      )}

      {state.actionItemState === 'toggle' && (
        <>
          <div className={`task`}>{task_title}</div>
          <ToggleSwitch toggle={toggle} onToggle={handleToggle} />
        </>
      )}
      {state.actionItemState === 'toggle-disabled' && (
        <>
          <div className={`task`}>{task_title}</div>
        </>
      )}
      {isFeedbackModalOpen && (
        <FeedbackModal
          task_title={task_title}
          task_id={task_id}
          transcript={transcript}
          sales_manager_id={sales_manager_id}
          sales_rep_id={sales_rep_id}
          onClose={onFeedbackModalClose}
          feedbackSubmitted={feedbackSubmitted}
          setfeedbackSubmitted={setfeedbackSubmitted}
        />
      )}
      {state.isDetailModalOpen ? (
        <ActionItemDetailModal
          task_title={task_title}
          // [ ] TODO Pass in connieScore
          task_id={task_id}
          sales_manager_id={sales_manager_id}
          sales_rep_name={sales_rep_name}
          onClose={closeDetailModal}
          openFeedbackModalAndCloseDetailModal={
            openFeedbackModalAndCloseDetailModal
          }
          transcript={transcript}
          notes={textareaInput}
          onTextareaInput={handleTextareaInput}
        />
      ) : null}
    </div>
  );
};
const actionHandlers = {
  default: {
    mouse_enter: (state) => ({ ...state, actionItemState: 'hover' }),
  },
  hover: {
    mouse_leave: (state) => ({ ...state, actionItemState: 'default' }),
    click: (state) => ({
      ...state,
      actionItemState: 'details',
      previousClick: '',
    }),
  },
  toggle: {
    mouse_enter: (state) => ({ ...state, actionItemState: 'toggle-hover' }),
  },
  'toggle-hover': {
    click: (state) => ({
      ...state,
      actionItemState: 'toggle-details',
      previousClick: '',
    }),
    mouse_leave: (state) => ({ ...state, actionItemState: 'toggle' }),
  },
  'toggle-details': {
    click_outside: (state) => ({
      ...state,
      actionItemState: 'toggle',
      previousClick: '',
    }),
    click: (state) =>
      state.previousClick !== 'DateWrapper'
        ? {
            ...state,
            isDetailModalOpen: true,
            actionItemState: 'toggle',
            previousClick: '',
          }
        : { ...state, previousClick: '' },
  },
};

function reducer(state, action) {
  if (action.type === 'previous-clicked') {
    return { ...state, previousClick: 'DateWrapper' };
  }
  if (action.type === 'toggle-decline') {
    return { ...state, actionItemState: 'toggle' };
  }
  if (action.type === 'close-modal') {
    return { ...state, isDetailModalOpen: false };
  }
  if (action.type === 'set_toggle_disabled') {
    return { ...state, actionItemState: 'toggle-disabled' };
  }

  const handler = actionHandlers[state.actionItemState]?.[action.type];
  return handler ? handler(state) : { ...state, previousClick: '' };
}
ActionItem.propTypes = {
  actionItemState: PropTypes.oneOf([
    'default',
    'details',
    'toggle-details',
    'toggle-hover',
    'hover',
    'toggle',
  ]),
  task_title: PropTypes.string,
};
